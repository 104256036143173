<template>
  <v-bottom-navigation
    app
    fixed
    grow
    :modelValue="selected_page"
    color="green"
  >
    <v-btn :value="item.name" v-for="item in menu_items" :key="item.title" @click="$.emit('select-menu-item', item)">
      <v-icon>{{item.mdi}}</v-icon>
      <span>{{ item.title }}</span>
      
    </v-btn>

  </v-bottom-navigation>
</template>

<script>

export default {
  name: "BottomMenu",
  props: {
    menu_items: {
        type: Array,
        default: () => []
    },
    selected_page: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-icon {
    background-size: 100% auto;
}

.v-btn {
    letter-spacing: normal;
  }

</style>
