<template>
<div class="absolute">
    <v-app-bar density="compact"   color="green-lighten-4">
          <v-btn icon @click="doExit(0)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
  
          <v-app-bar-title>Аналоги для {{ internalRecipe.name }}</v-app-bar-title>
          
  
    </v-app-bar>
  
    <v-main>
  
  
      
  
     
          <v-list v-model:opened="opened" class="pa-0">
              <v-list-group value="0">
                  <template v-slot:activator="{ props }">
                      <v-list-item  class="bg-green-lighten-4" v-bind="props" title="Основной продукт">
                      </v-list-item>
                  </template>
  
                  <v-list-item :title="product._it.name">
                  
               
                  <v-table density="compact" class="head" >
                      <thead>
                      <tr>
                         
                          <th class="text-left text-subtitle-2">
                          Б
                          </th>   
                          <th class="text-left text-subtitle-2">
                          Ж
                          </th> 
                          <th class="text-left text-subtitle-2">
                          У
                          </th>                  
                          <th class="text-left text-subtitle-2">
                          Ккал
                          </th>

                          <th class="text-left text-subtitle-2">
                          Цена / 100 гр
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-orange-darken-3">
                        
                          <td class="text-left text-subtitle-2">
                          {{ product._it.proteins ? this.getVal(product._it.proteins) : "0" }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ product._it.fats ? this.getVal(product._it.fats) : "0" }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ product._it.carbohydrates ? this.getVal(product._it.carbohydrates) : "0" }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ product._it.energy ? this.getVal(product._it.energy) : "0" }}
                          </td>

                          <td class="text-left text-subtitle-2">
                            {{ getItemPrice(product._it) }}
                          </td>
                      </tr>
                     
                      </tbody>
                  </v-table>
                  </v-list-item>
                 
  
              </v-list-group>
  
              <v-list-group value="1">
                  <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" class="bg-green-lighten-4" >
                          <v-list-item-title class="d-flex">
                              
                              Аналоги
                              <v-spacer></v-spacer>
                              <v-icon v-if="opened.includes('1')" class="align-right" @click.stop="productAdd">mdi-plus-circle</v-icon>
                              
                          </v-list-item-title>
                      </v-list-item>
     
                  </template>
  
                
                  <v-list-item v-for="(prod) in internalRecipe.items" :key="prod"
                  
                      :title="prod.name"
                     
                      :class="weightIsSet(prod)">
  
                      <template v-slot:append>
                          <div class="d-flex flex-column actions">
                              <v-icon @click="makePrimaryDialog(0, prod)" class="pa-5">mdi-arrow-expand-up</v-icon>                            
                              <v-icon @click="removeDialog(0, prod)" class="pa-5">mdi-trash-can-outline</v-icon>
                          </div>
                      </template>
  
                      <template v-slot:subtitle>
                          <v-table  density="compact">
                              <thead>
                                  <tr>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      Б
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      Ж
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      У
                                      </th>
                                      
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                      Ккал
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                      Цена / 100 гр
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr @click="weightDialog(0, prod)" >
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod.proteins ? this.getVal(prod.proteins) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod.fats ? this.getVal(prod.fats) : "0") }} 
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod.carbohydrates ? this.getVal(prod.carbohydrates) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod.energy ? this.getVal(prod.energy) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ getItemPrice(prod) }}
                                          
                                      </td>
                                  </tr>
                              </tbody>
                          </v-table>
                              
                      </template>
                  </v-list-item>
              
              </v-list-group>
  
  
  
              <v-list-group value="2">
  
                  <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" title="Подсказка" class="bg-green-lighten-4" ></v-list-item>
                  </template>
  
                  <v-list-item>
  
                    Добавьте продукты заменители основного продукта из разных магазинов, для корретного формирования списка покупок.
                  </v-list-item>
              </v-list-group>
          </v-list>
  
  
  
  
  
       
      <v-dialog v-model="dialog" persistent width="auto">
          <v-card>
              <v-card-text>Сохранить аналоги перед выходом?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="doExit(-1)">
                  Не сохранять
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="doExit(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_weight" persistent width="auto">
          <v-card>
              <v-card-text>Укажите вес продукта</v-card-text>
              <v-card-text>
                  <v-text-field density="compact" hide-details="true" v-model="prod_weight">
  
                      <template v-slot:prepend>
                          <v-icon icon="mdi-minus-circle" @click="increaseWeight(-50)" />
                      </template>    
  
                      <template v-slot:append>
                          <v-icon icon="mdi-plus-circle" @click="increaseWeight(50)" />
                      </template>    
  
                      
                  </v-text-field>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="weightDialog(-1)">
                  Закрыть
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="weightDialog(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_remove" persistent width="auto">
          <v-card>
              <v-card-text>Удалить продукт из аналогов?</v-card-text>
              
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="green-darken-1"  variant="text" @click="removeDialog(-1)">
                  Отмена
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="removeDialog(1)">
                  Удалить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_makeprimary" persistent width="auto">
          <v-card>
              <v-card-text>Установить продукт основным?</v-card-text>
              
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="green-darken-1"  variant="text" @click="makePrimaryDialog(-1)">
                  Отмена
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="makePrimaryDialog(1)">
                  Установить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
    </v-main>

    <v-footer
    app
    fixed
    grow
    class="justify-center"
    color="transparent"
    v-if="internalRecipe.changed"
  >

    <v-btn   color="green-darken-3" prepend-icon="mdi-content-save"  size="large" @click="doExit(2)">Сохранить</v-btn>
    
  </v-footer>
</div>
  </template>
  
  <script>
  export default {
    name: 'PageRecipeContent',
    emits: ['swap-exit', 'recipe-store', 'swap-product-add', 'product-swap', 'submenu-close', 'catalog-callback'],
    props: {
      msg: String,
      recipe: Object,
      product: Object
    },
    data() {
      return {
          recipes: [1,2,3],
          header_search: false,
          internalRecipe: {portions: 1, items: []},
          dialog: false,
          dialog_weight: false,
          dialog_remove: false,
          dialog_makeprimary: false,
          prod_weight: 0,
          prod: null,
          variants: ['normal', 'edit'],
          variant: 'normal',
          changed: false,
          show_desc: false,
          opened: ["0", "1"]
      }
    },
    methods: {
      productAdd() {
        this.$.emit('swap-product-add', this.internalRecipe, this.productsAddCallback);
      },
      productAddCallback(payload, products) {

        console.log(products);

        this.internalRecipe.changed = true;

        //this.internalRecipe.items.push(product);
      },
      setChangedState() {
          this.internalRecipe.changed = true;
      },
      getSubtitle(prod) {
          return "<table><tr><td>" + 
              (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") + "</td><td>" +                       
              (prod._it.fats ? this.getVal(prod._it.fats) : "0") + "</td><td>" +            
              (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") + "</td></tr></table>";
      },
      toggleHeader() {
          this.header_search = !this.header_search
      },
      increase(val, obj, field) {
  
          console.log(obj[field])
          this.internalRecipe.changed = true;
  
          if (obj[field]) {
  
              if (obj[field] + val > 0)
                  obj[field] = parseFloat(obj[field]) + val;
          } 
  
      },
      weightIsSet(prod) {
          if (prod.unit == 'nan')
              return {"bg-red-lighten-4": true}
      },
      increaseWeight(val) {
          if (val < 0 && this.prod_weight >= -val)
              this.prod_weight = parseFloat(this.prod_weight) + val;
          if (val > 0)
              this.prod_weight = parseFloat(this.prod_weight) + val ;
      },
      weightDialog(num, prod) {
          if (num == 0) {
              this.prod_weight = prod.quantity;
              this.prod = prod;
              this.dialog_weight = true;
          }
  
          if (num == -1)
              this.dialog_weight = false;
  
          if (num == 1) {
              this.prod.quantity = this.prod_weight;
              this.dialog_weight = false;
              this.internalRecipe.changed = true;
              //this.$.emit('recipe-store', this.internalRecipe);
          }
      },
      removeDialog(num, prod) {
          if (num == 0) {
              this.prod = prod;
              this.dialog_remove = true;
          }
  
          if (num == -1)
              this.dialog_remove = false;
  
          if (num == 1) {
              
              this.dialog_remove = false;
              this.internalRecipe.changed = true;
              
              this.internalRecipe.items = this.internalRecipe.items.filter((item) => {
                
                  return item.id != this.prod.id;
              })
          }
      },
      makePrimaryDialog(num, prod) {
          if (num == 0) {
              this.prod = {
                _it: prod
              };
              this.dialog_makeprimary = true;
          }
  
          if (num == -1)
              this.dialog_makeprimary = false;
  
          if (num == 1) {

            if (this.internalRecipe.changed) {
                this.internalRecipe.changed = false;
                this.$.emit('swap-store', this.internalRecipe, false);
            }
              
            this.dialog_makeprimary = false;
            this.$.emit('swap-confirm', this.recipe, this.product, this.prod)
              /*this.internalRecipe.changed = true;
              
              this.internalRecipe.items = this.internalRecipe.items.filter((item) => {
                  return item.product != this.prod.product;
              })*/
          }
      },
      swapProduct(prod) {
          this.$.emit('product-swap', prod);
      },
      doExit(num) {
          if (num == 0) {
              if (this.internalRecipe.changed)
                  this.dialog = true;
              else
                  this.$.emit('swap-exit');
          }
  
          if (num == -1)
              this.$.emit('swap-exit');
  
          if (num == 1) {
              this.$.emit('swap-store', this.product, this.internalRecipe.items, true);
          }
  
          if (num == 2) {
              this.internalRecipe.changed = false;
              this.$.emit('swap-store', this.product, this.internalRecipe.items, false);
              
          }
      }, 
      productAddEvent(product) {
          console.log("Product add", product)
           console.log(this.internalRecipe);
          //this.internalRecipe.items.push(product);
      },
      remove(key) {
          console.log(key);
          
          this.internalRecipe.items.splice(key, 1);
          
      },
      getVal(val, fixed=0) {
  
          
          if (!isNaN(val)) {
              return parseFloat(val).toFixed(fixed);
          } else {
              return parseFloat("0").toFixed(fixed);
          }
      },
      getSum(type) {
  
          var res = 0.0;
          var types = {'u': 'carbohydrates', 'g': 'fats', 'b': 'proteins', 'e': 'energy', 'w': 'weight', 'q': 'quantity'};
  
          //console.log((this.internalRecipe.items));
  
          if (Object.keys(types).includes(type)) {
  
              if (this.internalRecipe.items) {
  
                  this.internalRecipe.items.forEach((item) => {
  
                      if (item[types[type]]) {
                          res += parseFloat(item[types[type]])
                      }
  
                      if (item._it && item._it[types[type]] && type == 'p' && item._it.weight) {
                          res += parseFloat(item._it.price) / parseFloat(item._it.weight)
                          return;
                      }
  
                      if (item._it && item._it[types[type]])
                          res += parseFloat(item._it[types[type]]) * parseFloat(item.quantity) / 100
                  });
  
              }
          }
  
          //console.log(res.toFixed(1) * 100);
  
          return res.toFixed(2);
      },
      getPrice() {
  
          var res = 0.0;
          
  
          //console.log((this.internalRecipe.items));
  
          if (this.internalRecipe.items) {
  
              this.internalRecipe.items.forEach((item) => {
  
                  if (item._it && item._it.unit == '100г') {
                      res += item.quantity * parseFloat(item._it.price) / 100
                      return;
                  }
  
                  if (item._it && item._it.unit == 'кг') {
                      res += item.quantity * parseFloat(item._it.price) / 1000
                      return;
                  }
  
                  if (item._it && item._it.unit == 'шт' && item._it.weight) {
                      res += item.quantity * parseFloat(item._it.price) / parseFloat(item._it.weight)
                      return;
                  }
              });
  
          }
          
  
          //console.log(res.toFixed(1) * 100);
  
          return res.toFixed(2);
      },
      getItemPrice(item, quantity = 100) {
        
        var res = 0.0;


        if (item && item.unit == '100г') {
            res += quantity * parseFloat(item.price) / 100
        }

        if (item && item.unit == 'кг') {
            res += quantity * parseFloat(item.price) / 1000
        }

        if (item && item.unit == 'шт' && item.weight) {
            res += quantity * parseFloat(item.price) / parseFloat(item.weight)
        }

        return res.toFixed(0);
      },
      toggleMode() {
          if (this.variant == 'normal')
              this.variant = 'edit';
          else 
              this.variant = 'normal';
  
          
  
      }
    },
    mounted() {

      //console.log(this.recipe);
  
      this.internalRecipe.items = this.product._analogs;

      this.internalRecipe.name = this.recipe.name;
      /*
      if (!this.internalRecipe.portions)
          this.internalRecipe.portions = 1;*/
  
  
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .v-col {
      padding-top: 0;
      padding-bottom: 0;
  }
  .v-list-subheader {
      padding-top: 0;
  }
  
  .count {
      min-width: 130px;
  }
  
  tr.hidden {
      display: none;
  }

  
  .head {
      padding: 0 8px;
  }
  
  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
      padding: 0 8px;
  }
  </style>
  