<template>
    <template v-if="submenu == 'main'">

      <v-card>
      <v-toolbar density="compact" color="green-lighten-4">
        <v-btn icon  @click="toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!mode_search"  @click="toggleSearch">Выбрать рецепт</v-toolbar-title>

        <v-text-field density="compact" hide-details v-if="mode_search" ref="search_input" v-model="search_filter" @keyup="changeFilter"></v-text-field>

        
            <v-btn  @click="$.emit('submenu-close')" v-if="closeable">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          

      </v-toolbar>


      
        <v-container class="mb-6" style="height: 100%; overflow-y: scroll">
          <v-list align="start">
            <v-list-item v-for="recipe in recipes" :key="recipe.id" cols="6" class="pa-1" :title="recipe.name" @click="selectRecipe(recipe)">
              
            </v-list-item>
          </v-list>

          <slot></slot>
          
        </v-container>

        
    
   
      </v-card>
  </template>

    

</template>

<script>

import Net from "@/common.js"

export default {
  name: 'FrameListRecipes',

  emits: ['categories-show', 'logout', 'product-select', 'configure-users', 'plan-users', 'submenu-close', 'catalog-callback'],
  props: {
    msg: String,
    recipes: Array,
    return_product: Boolean,
    closeable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      submenu: 'main',
      mode_search: false,
      search_filter: '',
      selectedShop: null,
      selectedSubCat: null,
      products: [],
      favorites: [], 
      filterTimeout: null,
    }
  },
  methods: {
    toggleSearch() {
        this.mode_search = !this.mode_search
        this.selectedSubCat = null;
        this.submenu = 'catalog'

        /*if (this.mode_search) {
            console.log(this.$refs);
           //this.$refs.search.focus();

           this.$nextTick(() => {
            console.log(this.$refs);
            this.$refs.search_input.focus();
            })
        }*/

        this.$.emit('product-search');
    },
    changeFilter() {
      this.$.emit('product-filter', this.search_filter)
    },
    selectRecipe(recipe) {

        this.$.emit('catalog-callback', recipe)
    },
    catalogSelect(cat) {

      this.mode_search = false;

      Net.fetch('categories/'+cat.id, {method: 'GET', shop: this.selectedShop.id}).then((jsonData) => {
        console.log(jsonData);

        this.subCategories = jsonData;
        this.subCategories.child = this.subCategories.child.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.submenu = 'catalog';
        
        this.subCategorySelect(this.subCategories.child[0]);
      })

      console.log(cat);
    },
    subCategorySelect(subCat) {

      console.log(subCat);
      this.selectedSubCat = subCat;

      Net.fetch('products', {method: 'GET', shop: this.selectedShop.id, category: subCat.id}).then((jsonData) => {
        console.log(jsonData);
        this.products = jsonData.results;
      })
    },
    productSelect(product) {

      console.log(product)

      this.$.emit('catalog-callback', product)

      //PageRecipeContent.methods.productAddEvent(product)

      /*if (this.mainPageReturnProduct) {

        this.mainPageReturnProduct = false;   
        this.catalogCallback(product);       
      } else {
        this.productDialog.product = product;
        this.productDialog.show = true;
      }*/
      },

      productSearch() {
      this.mode_search = true;
      //this.subCategories = null;
      this.selectedPage = "catalog";
      this.selectedSubCat = null;
    },

    

    productFilter(productName) {
      
      let filter = {name: productName}

      if (this.selectedSubCat) {
        filter.category = this.selectedSubCat.id;
      }

      if (this.filterTimeout) {
        window.clearTimeout(this.filterTimeout);          
      }

      this.filterTimeout = window.setTimeout(() => {

          Net.fetch("products", {method: 'GET', ...filter}).then((jsonData) => {
            this.products = jsonData.results;
          })

          this.filterTimeout = null;
        }, 800);
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.shop {
  background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
}


.search_input {
  border: none;
  display: inline-flex;
  flex-grow: 4;
}
</style>
