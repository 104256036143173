import Net from "./common.js"

export default {
    recipesGet() {
        return Net.fetch('recipes', {method: 'GET'}).then((jsonData) => {
          console.log(jsonData);
          this.recipes = jsonData.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        });
      },
      recipeDelete(rec) {
        Net.fetch('recipes/'+rec.id, {method: 'DELETE'}).then(() => {
          this.recipesGet();
        });
      },
      recipeGetParams(item) {
          item.proteins = Net.getSum(item.items, 'b', item.portions);
          item.fats = Net.getSum(item.items, 'g', item.portions);
          item.carbohydrates = Net.getSum(item.items, 'u', item.portions);
          item.energy = Net.getSum(item.items, 'e', item.portions);
          item.weight = Net.getSum(item.items, 'q');
          item.price = Net.getPrice(item.items);
          item.unit = 'шт'
      },
      recipePush(recipe, product) {
        console.log(recipe);
        //this.currentRecipe = recipe;
        //this.selectedPage = 'recipe'
        
        this.mainPageReturnProduct = true;
        this.catalogCallback = (item) => {
          this.recipeProductAdd(this.currentRecipe, item)
        }
        this.recipeShow(recipe).then(() => { this.productSelect(product) });
      },
      recipeShow(recipe) {
  
        if (recipe) {
  
          return Net.fetch('recipes/'+recipe.id, {method: 'GET'}).then((jsonData) => {
            console.log(jsonData);                 
  
            return this.recipeLoadProducts(jsonData.items).then((data) => {
              console.log("DATA", data)
              jsonData.items = data;
              this.currentRecipe = jsonData;
              this.selectedPage = 'recipe'
            });
            
          })     
        } else {
          this.currentRecipe = {id:0, name: "Новый рецепт", items: []};
          this.selectedPage = 'recipe'
        }
  
        
      },
      recipeLoadProducts(items) {

        console.log("ITEMS", items)
          return Promise.all(items.map((item) => {
            return Net.fetch('products/'+item.product, {'method': 'GET'});
          })).then((prods) => {

            console.log("ITEMS", items)

            var analogs = [];
            
            prods.forEach((_, key) => {
              items[key]._it = prods[key];
              if (items[key].analogs)
                analogs.push(this.recipeLoadAnalogs(items[key]))
              else 
                analogs.push([])
            });
  
            return Promise.all(analogs).then((res) => {

              console.log("RES", res)
                res.forEach((r, i) => {
                  items[i]._analogs = r;
                })

                return items;
            });
            
          })
      },
      recipeLoadAnalogs(item) {
        return Promise.all(item.analogs.map((a) => {
          return Net.fetch('products/'+a, {'method': 'GET'});
        }))
    },
      recipeExit() {
        this.selectedPage = 'recipes'
        return true;
      },
      recipeStore(newRecipe, closePage) {
        
        let path = "recipes";
        let data = {method: "POST", ...newRecipe}
  
        if (newRecipe.id > 0) {
          path += "/" + newRecipe.id;
  
          data = {method: 'PUT', ...newRecipe}
        }
  
        Net.fetch(path, data).then((jsonData) => {
          console.log(jsonData);
        })
        .then(this.recipesGet)
        .then(() => {
          if (closePage) this.recipeExit();
        });
      },
      recipeProductsAdd(internalRecipe, products) {

        var list = [];
        var self = this;

        console.log(products)

        products.forEach((productid) => {

          /*internalRecipe.items.push(
            {
              quantity: 100,
              _it: productid
            });*/

            this.recipeLoadProducts([{product: productid.id, quantity: 100}]).then((items) => {
      
              internalRecipe.items.push(...items)
                
              //console.log(product)
        
            }) 
          

        });
  

        return Promise.all(list).then(() => {
          self.selectedPage = "recipe";
        })        

       /* internalRecipe.changed = true;
        
        this.currentRecipe = internalRecipe;*/
        
      },

      storePlan(plan) {
        console.log(plan)
      }
}