export default {
    version: "1.0",
    baseUrl: 'https://dobroed.it-light.ru/api/v1/',
    _token: null,
    
    fetch (path, params)  {
        
        console.log(path);
        //console.log(params);

        let method = "POST";
        let queryString = "";

        if (params && params.method) {
            method = params.method;
            delete params.method;
        }

        let headers = {
            'Content-Type': 'application/json',
        }

        if (method == "GET") {
            /*queryString = '?' + Object.keys(params).map((key) => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
            }).join("&");*/

            queryString = '?' + new URLSearchParams(params).toString();
            //headers = [];
        }

        

        if (this.hasToken())
            headers["Authorization"] = "Token " + this._token;

        //console.log(headers);

        return fetch(this.baseUrl + path + '/' + queryString, {
            method: method,
            headers: headers,
            body: method == "GET" ? null : JSON.stringify(params)
        }).then(async(response) => {

            console.log(response);

            if (response.status == 204) {
                return response.text();
            } else if (response.ok) {

                return response.json();
            } else {
                throw Error("Status code "+response.status+await response.text())
            }
        });

    },    
    async notify(text, params) {
        
        
        console.log("111NOTIFY!!!!!!!");    

        let worker = await this.getWorker();
        console.log(worker);
        if (worker)
            worker.showNotification(text, params);

        console.log(window.Android)

        if (window.Android) {
            let message = text;
            if (params && params.body)
                message = params.body

            window.Android.notify(text, message)
        }

        /*.then((worker) => {
            console.log("222NOTIFY!!!!!!!");  
            worker.showNotification(text, params);
        })
        .catch((e) => {
            console.log("333NOTIFY!!!!!!!");    
            console.log(e);    
        });*/
        
        
        
    },

    getWorker() {     
        return navigator.serviceWorker.getRegistration('worker.js');
    },

    async registerWorker() {

        if (window.Notification) {
            await Notification.requestPermission().then(async () => {
                if (Notification.permission === 'granted') {
                    await navigator.serviceWorker.register("service-worker.js");
                }
            });
        }
    },

    hasToken() { 
        
        if (localStorage._token)
            this._token = localStorage._token;

        //console.log(localStorage)

        return this._token != null;
    },

    setToken(token) {
        this._token = token;
        localStorage._token = token;
    },

    getToken() {
        if (localStorage._token)
            this._token = localStorage._token;

        return this._token;
    },
    clearToken() {
        this._token = null;
        delete localStorage._token;

    },

    getLastLogin() {

        let list = []

        if (localStorage.lastLogin)
            list = JSON.parse(localStorage.lastLogin);

        return list.map((item, index) => { return {index: index, name: item.name} });
    },

    setLastLogin(name, credentials) {
        
        let list = [];

        if (localStorage.lastLogin) {
            list = JSON.parse(localStorage.lastLogin);
        }        
        
        list.unshift({name: name, credentials: credentials});

        if (list.length > 3)
            list.splice(3)

        localStorage.lastLogin = JSON.stringify(list); 
    },

    updateLastLoginName(user, name) {
        let list = []

        if (localStorage.lastLogin)
            list = JSON.parse(localStorage.lastLogin);

        list.forEach((item) => { 

            if (item.credentials.user == user) {
                item.name = name;
            }
        });

        localStorage.lastLogin = JSON.stringify(list); 
    },

    loginByStoredCredentials() {

    },

    getSum(items, type, divider = 1) {

        var res = 0.0;
        var types = {'u': 'carbohydrates', 'g': 'fats', 'b': 'proteins', 'e': 'energy', 'w': 'weight', 'q': 'quantity'};

        //console.log((this.internalRecipe.items));

        if (Object.keys(types).includes(type)) {

            if (items) {

                items.forEach((item) => {

                    if (item[types[type]] != null) {
                        res += parseFloat(item[types[type]])
                        return;
                    }

                    if (item._it && item._it[types[type]] && type == 'q' && item._it.weight) {
                        res += parseFloat(item._it.price) / parseFloat(item._it.weight)
                        return;
                    }

                    if (item._it && item._it[types[type]])
                        res += parseFloat(item._it[types[type]]) * parseFloat(item.quantity) * (item.type == "recipe" ? 100 : 1) / 100
                });

            }
        }

        //console.log(res.toFixed(1) * 100);

        return parseInt((res / divider).toFixed(0));
    },

    getPrice(items, divider = 1) {

        var res = 0.0;
        

        //console.log((this.internalRecipe.items));

        if (items) {

            items.forEach((item) => {

                if (item._it && item._it.unit == '100г') {
                    res += item.quantity * parseFloat(item._it.price) / 100
                    return;
                }

                if (item._it && item._it.unit == 'кг') {
                    res += item.quantity * parseFloat(item._it.price) / 1000
                    return;
                }

                if (item._it && item._it.unit == 'шт' && item._it.weight) {
                    res += item.quantity * parseFloat(item._it.price) / parseFloat( (item.type == "recipe" ? item._it.portions : item._it.weight) ) 
                    return;
                }
            });

        }
        

        //console.log(res.toFixed(1) * 100);

        return (res / divider).toFixed(0);
    },

    getWeight(items, divider = 1) {

        var res = 0.0;
        

        //console.log((this.internalRecipe.items));

        if (items) {

            items.forEach((item) => {

                if (item._it && item._it.unit == '100г') {
                    res += item.quantity
                    return;
                }

                if (item._it && item._it.unit == 'кг') {
                    res += item.quantity / 10
                    return;
                }

                if (item._it && item._it.unit == 'шт' && item._it.weight) {
                    res += item.quantity / parseFloat(item._it.weight) * 100
                    return;
                }
            });

        }
        

        //console.log(res.toFixed(1) * 100);

        return (res / divider).toFixed(0);
    },
    
} 
