<template>
<div class="absolute">
    <v-app-bar density="compact"   color="green-lighten-4">
          <v-btn icon @click="doExit(0)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
  
          <v-app-bar-title v-if="!header_search">План питания</v-app-bar-title>
          
  
    </v-app-bar>
  
    <v-main>
  
  
      
      <v-card>
            <!-- <v-list-group :value="String(index)" @click="opened = [String(index)]" >-->
            <v-card-title>
                <v-select :items="users" item-title="name" item-value="id" v-model="currentUserName" @update:modelValue="changeUser"> 
                </v-select>
            </v-card-title>
        

            <v-slide-group
                show-arrows center-active
                >
                <v-slide-group-item>
                    <v-card
                        class="ma-2 pa-2 align-center "
                        
                                            
                        @click="createPlan"
                        >
                        <div >
                            <div class="text-center">Новый</div>
                            <div class="text-center">+</div>
                        </div>
                    </v-card>
                </v-slide-group-item>

                <v-slide-group-item
                    v-for="plan in plans"
                    :key="plan"
                    v-slot="{ isSelected, toggle }"
                >
                    <v-card
                        class="ma-2 pa-2 align-center "
                        
                        
                        :color="isSelected ? 'green-darken-4' : undefined"
                        @click="() => { if (isSelected) return; changePlan(plan); toggle(...arguments) }"
                        >
                        <div >
                            <div class="text-center plan">{{  prettyDate(plan.date)  }}</div>
                            <div class="text-center">{{ dayOfWeek(plan.date) }}</div>
                        </div>
                    </v-card>

                    
                </v-slide-group-item>

                
            </v-slide-group>
               
                  <v-table density="compact" class="head" >
                      <thead>
                      <tr>
                          <th></th>
                          <th class="text-left text-subtitle-2">
                          Б
                          </th>   
                          <th class="text-left text-subtitle-2">
                          Ж
                          </th> 
                          <th class="text-left text-subtitle-2">
                          У
                          </th>                  
                          <th class="text-left text-subtitle-2">
                          Ккал
                          </th>
                          <th class="text-left text-subtitle-2">
                          Цена
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-orange-darken-3">
                          <td class="text-left text-subtitle-2">
                          Цель
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ getParam('b') }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ getParam('g') }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ getParam('u') }}
                          </td>
                          <td class="text-left text-subtitle-2">
                            {{ getParam('k') }}
                          </td>

                          <td class="text-left text-subtitle-2">
                          
                          </td>
                      </tr>
                      <tr class="text-green-darken-3">
                          <td class="text-left text-subtitle-2">
                          Расчет
                          </td>
                          <td class="text-left text-subtitle-2">
                          {{  getSum(internalRecipe.items, 'b')  }}
                          </td>
                          <td class="text-left text-subtitle-2">
                          {{  getSum(internalRecipe.items, 'g')  }}
                          </td>
                          <td class="text-left text-subtitle-2">
                          {{  getSum(internalRecipe.items, 'u')  }}
                          </td>

                          <td class="text-left text-subtitle-2">
                          {{  getSum(internalRecipe.items, 'e')  }}
                          </td>
                          <td class="text-left text-subtitle-2">
                              {{ getPrice(internalRecipe.items)  }}
                          </td>
                      </tr>
                      </tbody>
                  </v-table>
                 
                </v-card>
     
          <v-list v-model:opened="opened" class="pa-0">
              
  
              <v-list-group value="0">
                  <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" class="bg-green-lighten-4" >
                          <v-list-item-title class="d-flex">
                              
                              Состав
                              <v-spacer></v-spacer>
                              <v-icon class="align-right" @click.stop="$.emit('product-add', internalRecipe)">mdi-plus-circle-outline</v-icon>
                              <v-icon class="align-right ml-6" @click.stop="$.emit('recipe-add', internalRecipe)">mdi-bookmark-plus-outline</v-icon>
                              
                          </v-list-item-title>
                      </v-list-item>
     
                  </template>
  
                
                  <v-list-item v-for="(prod) in internalRecipe.items" :key="prod"
                  
                      :title="prod._it.name"
                      
                      :class="weightIsSet(prod._it)">
  
                      <template v-slot:append>
                          <div class="d-flex flex-column actions">
                              
                              <v-icon @click="removeDialog(0, prod)" class="pa-5">mdi-trash-can-outline</v-icon>
                          </div>
                      </template>
  
                      <template v-slot:subtitle>
                          <v-table  density="compact">
                              <thead>
                                  <tr>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      Б
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      Ж
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                      У
                                      </th>
                                      
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                      Ккал
                                      </th>
                                      <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                      {{ prod.type == "recipe" ? "Порции" : "Вес" }}
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr @click="weightDialog(0, prod)" >
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod._it.fats ? this.getVal(prod._it.fats) : "0") }} 
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ (prod._it.energy ? this.getVal(prod._it.energy) : "0") }}
                                      </td>
                                      <td class="text-left text-subtitle-2">
                                          {{ prod.quantity ? this.getVal(prod.quantity, 0) : "0" }}
                                          <v-icon >mdi-pencil-outline</v-icon>
                                      </td>
                                  </tr>
                              </tbody>
                          </v-table>
                              
                      </template>
                  </v-list-item>
              
              </v-list-group>
             
          </v-list>
  

      <v-dialog v-model="dialog" persistent width="auto">
          <v-card>
              <v-card-text>Сохранить план питания перед выходом?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="doExit(-1)">
                  Не сохранять
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="doExit(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_weight" persistent width="auto">
          <v-card>
              <v-card-text>Укажите вес продукта/рецепта</v-card-text>
              <v-card-text>
                  <v-text-field density="compact" hide-details="true" v-model="prod_weight">
  
                      <template v-slot:prepend>
                          <v-icon icon="mdi-minus-circle" @click="increaseWeight(prod.type == 'recipe' ? -1 : -50)" />
                      </template>    
  
                      <template v-slot:append>
                          <v-icon icon="mdi-plus-circle" @click="increaseWeight(prod.type == 'recipe' ? 1 : 50)" />
                      </template>    
  
                      
                  </v-text-field>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="weightDialog(-1)">
                  Закрыть
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="weightDialog(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_remove" persistent width="auto">
          <v-card>
              <v-card-text>Удалить продукт?</v-card-text>
              
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="green-darken-1"  variant="text" @click="removeDialog(-1)">
                  Отмена
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="removeDialog(1)">
                  Удалить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-footer
    app
    fixed
    grow
    class="justify-center"
    color="transparent"
    
  >
<!-- v-if="internalRecipe.changed" -->
    <v-btn   color="green-darken-3" prepend-icon="mdi-content-save"  size="large" @click="storePlan">Сохранить</v-btn>
    
  </v-footer>
  
    </v-main>
</div>
  </template>
  
  <script>

  import Common from "@/common.js";

  import RecipeMethods from "@/recipeMethods.js";

  export default {
    name: 'PagePlan',
    emits: ['recipe-exit', 'recipe-store', 'recipe-product-add', 'product-swap', 'product-add'],
    props: {
      msg: String,
      recipe: Object,
      users: Array
    },
    data() {
      return {
          recipes: [1,2,3],
          currentPlan: 0,
          plan: [],
          header_search: false,
          internalRecipe: {changed: false, items: []},
          dialog: false,
          dialog_weight: false,
          dialog_remove: false,
          prod_weight: 0,
          prod: null,
          variants: ['normal', 'edit'],
          variant: 'normal',
          changed: false,
          show_desc: false,
          opened: ["0"],
          currentUser: null,
          currentUserName: '',
          energyBase: {
            '1':   [ 88.363, 13.398, 4.798, -5.678],
            '2': [447.594,  9.248, 3.099, -4.331],
          },
          activityBase: [
            0,
            1.2,
            1.375,
            1.55,
            1.725,
            1.9,
          ],
          plans: []
          
      }
    },
    methods: {
        dayOfWeek(d) {
            var t = new Date(d);
            var days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
            return days[t.getDay()];
        },
        prettyDate(d) {
            return d.split("-").reverse().join(".")
        },
      setChangedState() {
          this.internalRecipe.changed = true;
      },
      getSubtitle(prod) {
          return "<table><tr><td>" + 
              (prod.proteins ? this.getVal(prod.proteins) : "0") + "</td><td>" +                       
              (prod.fats ? this.getVal(prod.fats) : "0") + "</td><td>" +            
              (prod.carbohydrates ? this.getVal(prod.carbohydrates) : "0") + "</td></tr></table>";
      },
      toggleHeader() {
          this.header_search = !this.header_search
      },
      increase(val, obj, field) {
  
          console.log(obj[field])
          this.internalRecipe.changed = true;
  
          if (obj[field]) {
  
              if (obj[field] + val > 0)
                  obj[field] = parseFloat(obj[field]) + val;
          } 
  
      },
      weightIsSet(prod) {
          if (prod.unit == 'nan')
              return {"bg-red-lighten-4": true}
      },
      increaseWeight(val) {
          if (val < 0 && this.prod_weight >= -val)
              this.prod_weight = parseFloat(this.prod_weight) + val;
          if (val > 0)
              this.prod_weight = parseFloat(this.prod_weight) + val ;
      },
      weightDialog(num, prod) {
          if (num == 0) {
              this.prod_weight = prod.quantity;
              this.prod = prod;
              this.dialog_weight = true;
          }
  
          if (num == -1)
              this.dialog_weight = false;
  
          if (num == 1) {
              this.prod.quantity = this.prod_weight;
              this.dialog_weight = false;
              this.internalRecipe.changed = true;
              //this.$.emit('recipe-store', this.internalRecipe);
          }
      },
      removeDialog(num, prod) {
          if (num == 0) {
              this.prod = prod;
              this.dialog_remove = true;
          }
  
          if (num == -1)
              this.dialog_remove = false;
  
          if (num == 1) {
              
              this.dialog_remove = false;

              this.internalRecipe.changed = true;
              
              this.internalRecipe.items = this.internalRecipe.items.filter((item) => {
                  return item != this.prod;
              })

              

          }
      },
      doExit(num) {
          if (num == 0) {
              if (this.internalRecipe.changed)
                  this.dialog = true;
              else
                  this.$.emit('recipe-exit');
          }
  
          if (num == -1)
              this.$.emit('recipe-exit');
  
          if (num == 1) {
              this.$.emit('recipe-store', this.internalRecipe, true);
          }
  
          if (num == 2) {
              this.internalRecipe.changed = false;
              this.$.emit('recipe-store', this.internalRecipe, true);
              
          }
      }, 
      
      getVal(val, fixed=0) {
  
          
          if (!isNaN(val)) {
              return parseFloat(val).toFixed(fixed);
          } else {
              return parseFloat("0").toFixed(fixed);
          }
      },
      getSum: Common.getSum,
      getPrice: Common.getPrice,
      toggleMode() {
          if (this.variant == 'normal')
              this.variant = 'edit';
          else 
              this.variant = 'normal';
  
          
  
      },

      changePlan(plan) {

        //plan = this.plans[0];

        this.currentPlan = plan;

        return this.getPlan(plan.id)
      },

      getPlans() {

       /* var count = 10;
        var one_day = 24 * 60 * 60 * 1000;
        var start = Date.now() - count*one_day;

        this.plans = [];

        for(var i=0; i<count; i++) {
            this.plans.push({
                date: new Date(start+i*one_day).toLocaleDateString('en-CA')
            })
        }*/




        Common.fetch("meal_plan", {
            method: "GET"
        }).then((data) => {
            console.log("PLANS", data)
            this.plans = data.results.sort((a, b) => {
                if (a.date < b.date) return 1; // если первое значение больше второго
                if (a.date == b.date) return 0; // если равны
                if (a.date > b.date) return -1; // если первое значение меньше второго
            })

            if (this.plans.length) {
                setTimeout(() => {
                    document.querySelector('.plan').click()
                }, 100)
                
            }
        })

        
      },

      getNow() {

        function twoDigits(i) {

            var x = i.toString();

            if (x.length == 1)
                return "0"+x;

            return x;
        }

        var today = new Date();

        return today.getFullYear() + "-" + twoDigits(today.getMonth()+1) + "-" + twoDigits(today.getDate())
      },

      createPlan() {
        
        var d = this.getNow()

        if (this.plans && this.plans.length && this.plans[0].date == d) {
            document.querySelector('.plan').click()
            return
        }
        
        Common.fetch("meal_plan", {
            date: d,
            product_items: [],
            recipe_items: [],
            user: 5
        }).then(() => {
            
            this.getPlans();

        })
      },

      getPlan(id) {
        //id = 1

        return Common.fetch("meal_plan/" + id, {
            method: "GET"
        }).then((data) => {
            console.log(data)


            this.internalRecipe.changed = false;
            this.internalRecipe.items = []
            
            data.product_items.forEach((item) => {
                this.internalRecipe.items.push({
                    quantity: item.weight,
                    _it: item.product,
                    type: 'product'
                });
            });

            data.recipe_items.forEach((recipe) => {
                console.log("RECIPE", recipe)

                RecipeMethods.recipeLoadProducts(recipe.recipe.items).then((data) => {
                    
                    recipe.recipe.items = data

                    RecipeMethods.recipeGetParams(recipe.recipe)

                    this.internalRecipe.items.push({
                        quantity: recipe.quantity,
                        weight: recipe.weight,
                        _it: recipe.recipe,
                        type: 'recipe'
                    });
                })
            })

            /*RecipeMethods.recipeLoadProducts(data.recipe_items).then((items) => {
console.log(items)*/
                /*items.forEach((item) => {
                
                
                    this.internalRecipe.items.push({
                        quantity: item.quantity,
                        weight: item.weight,
                        _it: item.recipe,
                        type: 'recipe'
                    })
                });
            })*/

            

            console.log(this.internalRecipe)
        })
      },

      storePlan() {
        
        var plan = this.currentPlan; 

        var product_items = []
        var recipe_items = []

        this.internalRecipe.items.forEach((item) => {
            console.log(item)
            if (item.type == 'product')
                product_items.push({
                    product: item._it.id,
                    weight: item.quantity
                })
            if (item.type == 'recipe')
                recipe_items.push({
                    recipe: item._it.id,
                    weight: item.quantity,
                    quantity: item.quantity
                })
        })

        console.log(this.internalRecipe, plan, product_items)

        Common.fetch("meal_plan/" + plan.id, {
            method: "PUT",
            recipe_items: recipe_items,
            product_items: product_items,
            date: plan.date
        })
      },

      getParam(type) {

            //console.log(this.currentUser)

            if (!this.currentUser)
                return 0;

            if (type == 'k') {
                var base = this.energyBase[this.currentUser.sex];
                return (this.activityBase[this.currentUser.lifestyle] * (base[0] + base[1]*this.currentUser.weight + base[2]*this.currentUser.height + base[3]*this.currentUser.age)).toFixed(0);
            }

            if (type == 'u') {
                return (this.getParam('k') * this.currentUser.carbohydrates / 100 / 4).toFixed(0)
            }

            if (type == 'b') {
                return (this.getParam('k') * this.currentUser.protein / 100 / 4).toFixed(0)
            }

            if (type == 'g') {
                return (this.getParam('k') * this.currentUser.fat / 100 / 9).toFixed(0)
            }

        },
        changeUser(user) {
            console.log(user)
            this.users.forEach((u) => {
                if (u.id == user)
                    this.currentUser = u;
            })
        }
      
    },
    watch: {
        plan(newVal) {
            console.log("Plan new val", newVal);
        }
    },
    mounted() {

        console.log(12121212121)
        this.getPlans()

        setTimeout(() => {
            this.plans = this.plans.concat(this.plans)
        }, 2000)
        
        this.currentUser = this.users[0];
        
        console.log("current user", this.currentUser);
        this.currentUserName = this.users[0].name;
    
    
        //this.internalRecipe = this.recipe;
        

        console.log(this.plan)

        this.internalRecipe.items = this.plan;

        console.log(this.internalRecipe)
  
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .v-col {
      padding-top: 0;
      padding-bottom: 0;
  }
  .v-list-subheader {
      padding-top: 0;
  }
  
  .count {
      min-width: 130px;
  }
  
  tr.hidden {
      display: none;
  }
  
  .absolute {
      position: fixed;
      margin: 0 12%;
      bottom: 6%;
      
      z-index: 9999;
      width: 74%;
      
  }
  
  .head {
      padding: 0 8px;
  }
  
  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
      padding: 0 8px;
  }
  </style>
  