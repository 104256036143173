<template>
    <div class="absolute">

  <v-app-bar density="compact"   color="green-lighten-4">
        <v-btn icon @click="doExit(0)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-app-bar-title v-if="!header_search">{{ internalBuyList.name }}</v-app-bar-title>
        <v-text-field density="compact" hide-details="true" v-if="header_search" v-model="internalBuyList.name"></v-text-field>

        <v-btn icon @click="toggleHeader">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>

        <v-btn variant="text" icon="mdi-share-variant"></v-btn>

        <v-btn variant="text" icon="mdi-plus-circle" @click="$.emit('buylist-product-add', internalBuyList)"></v-btn>

        <!--<template v-slot:extension >


           
            <v-menu>
            Состав
            <v-spacer/>
            <v-btn icon  @click="$.emit('recipe-product-add')">
                <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
            </v-menu>
            
        </template>-->
  </v-app-bar>


  <v-main>
    <v-list v-for="(prod) in internalBuyList.items" :key="prod" >
        <v-list-item 
            append-icon="mdi-pencil-outline"
            :title="prod._it.name"
            :subtitle="prod">

            <template v-slot:subtitle="{ subtitle }">
                <v-table  density="compact">
                    <thead>
                        <tr>
                            <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                            Б / Ж / У
                            </th>
                            
                            <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                            Ккал
                            </th>
                            <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                            Количество
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left text-subtitle-2">
                                {{ (subtitle._it.proteins ? this.getVal(prod._it.proteins) : "0.00") }} /
                            
                                {{ (subtitle._it.fats ? this.getVal(prod._it.fats) : "0.00") }} /
                            
                                {{ (subtitle._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0.00") }}
                            </td>
                            <td class="text-left text-subtitle-2">
                                {{ (subtitle._it.energy ? this.getVal(prod._it.energy) : "0.00") }}
                            </td>
                        <td class="text-left text-subtitle-2">
                                {{ prod.quantity ? this.getVal(prod.quantity, 0) : "0" }}
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                    
            </template>
        </v-list-item>
    </v-list>

     


     
    <v-dialog v-model="dialog" persistent width="auto">
        <v-card>
            <v-card-text>Сохранить список перед выходом?</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  color="red-darken-1"  variant="text" @click="doExit(-1)">
                Не сохранять
            </v-btn>
            <v-btn color="green-darken-1" variant="text" @click="doExit(1)">
                Сохранить
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

  </v-main>
</div>
</template>

<script>
export default {
  name: 'PageBuyListContent',
  emits: ['buylist-exit', 'buylist-store', 'buylist-product-add'],
  props: {
    msg: String,
    buylist: Object
  },
  data() {
    return {
        recipes: [1,2,3],
        header_search: false,
        internalBuyList: {portions: 1, items: []},
        dialog: false,
        variants: ['normal', 'edit'],
        variant: 'normal'
    }
  },
  methods: {
    getSubtitle(prod) {
        return "<table><tr><td>" + 
            (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") + "</td><td>" +                       
            (prod._it.fats ? this.getVal(prod._it.fats) : "0") + "</td><td>" +            
            (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") + "</td></tr></table>";
    },
    toggleHeader() {
        this.header_search = !this.header_search
    },
    increase(val, obj, field) {

        console.log(obj[field])

        if (val < 0 && obj[field] >= -val)
            obj[field] = parseFloat(obj[field]) + val;
        if (val > 0)
            obj[field] = parseFloat(obj[field]) + val ;

    },
    doExit(num) {
        if (num == 0)
            this.dialog = true;

        if (num == -1)
            this.$.emit('buylist-exit');

        if (num == 1) {
            this.$.emit('buylist-store', this.internalBuyList);
        }
    }, 
    productAddEvent(product) {
        console.log("Product add", product)
         console.log(this.internalBuyList);
        //this.internalRecipe.items.push(product);
    },
    remove(key) {
        console.log(key);
        
        this.internalBuyList.items.splice(key, 1);
        
    },
    getVal(val, fixed=2) {

        
        if (!isNaN(val)) {
            return parseFloat(val).toFixed(fixed);
        } else {
            return parseFloat("0").toFixed(fixed);
        }
    },
    getSum(type) {

        var res = 0.0;
        var types = {'u': 'carbohydrates', 'g': 'fats', 'b': 'proteins', 'e': 'energy', 'w': 'weight', 'q': 'quantity'};

        //console.log((this.internalBuyList.items));

        if (Object.keys(types).includes(type)) {

            if (this.internalBuyList.items) {

                this.internalBuyList.items.forEach((item) => {

                    if (item[types[type]]) {
                        res += parseFloat(item[types[type]])
                    }

                    if (item._it && item._it[types[type]])
                        res += parseFloat(item._it[types[type]]) * parseFloat(item.quantity) / 100
                });

            }
        }

        //console.log(res.toFixed(1) * 100);

        return res.toFixed(2);
    },
    toggleMode() {
        if (this.variant == 'normal')
            this.variant = 'edit';
        else 
            this.variant = 'normal';

        

    }
  },
  mounted() {
    console.log(this.buylist);
    this.internalBuyList = this.buylist;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-col {
    padding-top: 0;
    padding-bottom: 0;
}
.v-list-subheader {
    padding-top: 0;
}

.count {
    min-width: 130px;
}

tr.hidden {
    display: none;
}

</style>
