<template>
    
    <v-main class="absolute">

        <v-container class="d-flex flex-column justify-start">
            <v-img  class="ma-6 pa-2 flex-shrink-1" src="@/assets/images/img_logo.svg" aspect-ratio="1"></v-img>
            <div class="text-h6 text-center mx-6 pa-2">Введите номер телефона для входа в профиль</div>

            <v-form ref="form" fast-fail @submit.prevent="validate">

                <v-text-field type="tel" placeholder="+7 (000) 000 00-00" clearable label="Номер телефона" v-model="phone" :rules="phone_rules" @keyup="changePhone"></v-text-field>
                <v-text-field type="number" placeholder="Введите код" v-model="pincodeInternal" v-if="state_getcode"/>
                
                <v-btn block @click="$.emit('login-continue', phone, pincodeInternal)" v-if="state_getcode" color="green">
                    Продолжить
                </v-btn>

                <v-btn type="submit" block v-if="!state_getcode"  color="green">
                    Получить код
                </v-btn>
            </v-form>
            <v-spacer>

            </v-spacer>

            <div class="my-2">
                <v-alert v-if="notifications_blocked" text="Уведомления заблокированы" type="warning" @click="permitNotify"></v-alert>
                <v-alert v-if="auth_error.length > 0" type="error"> {{ auth_error }} </v-alert>
            </div>

        </v-container>

        
  
    </v-main>
</template>

<script>
export default {
  name: 'PageLogin',
  props: {
    state_getcode: Boolean,
    auth_error: String,
    pincode: String
  },
  data: () => ({
    phone: '+7',
    pincodeInternal: '',
    notifications_blocked: false,
    interval: null,
    phone_rules: [
        value => {

            //console.log(value);
                  
            if (value.length == 12 && value[0]=='+' ) {              
                return true;
            }

            return 'Неверный формат'
        }
    ]
  }),
  watch: {
    pincode(newVal) {
        this.pincodeInternal = newVal;
    }
  },
  methods: {
    changePhone() {

        //console.log(this.phone);

        if (this.phone.length > 0 && this.phone[0]!='+') {

            if (this.phone[0] == '9') {
                this.phone = '+7' + this.phone;

            } else {
                //this.phone[0] = '+';
            }
            

        }
    },
    notificationBlocked() {
        this.notifications_blocked = (Notification.permission != 'granted');
    },
    permitNotify() {
        if (window.Notification) {
            Notification.requestPermission();
        }
    },
    async validate() {
        
        const { valid } = await this.$refs.form.validate()

        if (valid) 

            this.$.emit('login-getcode', this.phone);
    }
  },
  mounted() {
    this.notificationBlocked();
    this.interval = setInterval(this.notificationBlocked, 1000);
  },
    unmounted(){
        clearInterval(this.interval);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-main, .v-container {
    height: 100%;
}

.v-input {
    flex: none;
}
</style>
