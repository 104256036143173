import Net from "./common.js"

export default {
    buylistShow(list) {

        if (list) {
  
          return Net.fetch('product_lists/'+list.id, {method: 'GET'}).then((jsonData) => {
            console.log(jsonData);                 
  
            return this.buylistLoadProducts(jsonData).then((data) => {
              this.currentBuyList = data;
              this.selectedPage = 'buylist_content'
            });
            
          })     
        } else {
          this.currentBuyList = {id:0, name: "Новый список", items: []};
          this.selectedPage = 'buylist_content'
        }
  
        
      },
      
      buylistLoadProducts(data) {
          return Promise.all(data.items.map((item) => {
            return Net.fetch('products/'+item.product, {'method': 'GET'});
          })).then((items) => {
            
            data.items.forEach((item, key) => {
              item._it = items[key];
            });
  
            return data;
            
          })
      },
      buylistExit() {
        this.selectedPage = 'buylist'
        return true;
      },
      buylistStore(newBuyList) {
        
        let path = "product_lists";
        let data = {method: "POST", ...newBuyList}
  
        if (newBuyList.id > 0) {
          path += "/" + newBuyList.id;
  
          data = {method: 'PUT', ...newBuyList}
        }
  
        Net.fetch(path, data).then((jsonData) => {
          console.log(jsonData);
        })
        .then(this.buylistsGet)
        .then(this.buylistExit);
      },
      buylistProductAdd(internalBuyList, product) {

        this.buylistLoadProducts({items: [{product: product.id, quantity: 1}]}).then((data) => {
  
          this.currentBuyList.items.push(...data.items)
  
          this.selectedPage = "buylist_content";
          console.log(product)
    
        }) 
  
        this.currentBuyList = internalBuyList;
  
      },
      buylistDelete(list) {
        Net.fetch('product_lists/'+list.id, {method: 'DELETE'}).then(() => {
          this.buylistsGet();
        });
      },
      buylistsGet() {
        return Net.fetch('product_lists', {method: 'GET'}).then((jsonData) => {
          console.log(jsonData);
          this.buylist = jsonData.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        });
      },
      buylistPush(list, product) {
        console.log(list);
        //this.currentRecipe = recipe;
        //this.selectedPage = 'recipe'
        this.catalogCallback = (item) => {
          this.buylistProductAdd(this.currentBuyList, item)
        }
        this.mainPageReturnProduct = true;
        this.buylistShow(list).then(() => { this.productSelect(product) });
      }
}