<template>
    <template v-if="submenu == 'main'">

      <v-card>
        <v-toolbar density="compact" color="green-lighten-4">
          <v-btn icon  @click="toggleSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!mode_search"  @click="toggleSearch">Найти продукт</v-toolbar-title>

          <v-text-field density="compact" hide-details v-if="mode_search" ref="search_input" v-model="search_filter" @keyup="changeFilter"></v-text-field>

          
              <v-btn  @click="$.emit('submenu-close')" v-if="closeable">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            

        </v-toolbar>

        <v-container class="mb-6" style="height: 100%; overflow-y: scroll">
          <v-row align="start">
            <v-col v-for="shop in shops" :key="shop.id" cols="6" class="pa-1">
              <v-card class="shop" style="aspect-ratio: 16/9" @click="categoriesShow(shop)">
                
                  {{ shop.name }}
                
              </v-card>
            </v-col>
          </v-row>

          <slot></slot>
          
        </v-container>

      </v-card>
    </template>

    <FrameCategories 
        @catalog-select="catalogSelect" 
        @categories-exit="submenu = 'main'" 
        
        :shop="selectedShop" 
        :items="categories" 
        
        class="wrapped_item" 

        v-if="submenu == 'categories'"
    />        

    <FrameCatalog 
        @catalog-exit="mode_search = false ; selectedShop ? submenu = 'categories' : submenu='main'"
        @catalog-select="subCategorySelect" 
        @product-select="productSelect"
        @product-favorite="productFavorite"
        @product-filter="productFilter"
        @product-push-recipe="recipePush"
        @product-push-buylist="buylistPush"
        @toggle-mode="mode_search = !mode_search"

        :products="products" 
        :favorites="favorites"
        :subCategories="subCategories"
        :recipes="recipes"
        :mode_search="mode_search"
        :buylist="buylist"
        :inline="true"
        :multiselect="multiselect"
        
        v-else-if="submenu == 'catalog'"
    />

</template>

<script>

import FrameCategories from '@/components/FrameCategories.vue'
import FrameCatalog from '@/components/FrameCatalog.vue'
import Net from "@/common.js"

export default {
  name: 'FrameTitul',
  components: {
    FrameCategories,
    FrameCatalog
  },
  emits: ['categories-show', 'logout', 'product-select', 'configure-users', 'plan-users', 'submenu-close', 'catalog-callback'],
  props: {
    msg: String,
    shops: Array,
    return_product: Boolean,
    closeable: {
      type: Boolean,
      default: true
    },
    multiselect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submenu: 'main',
      mode_search: false,
      search_filter: '',
      selectedShop: null,
      selectedSubCat: null,
      products: [],
      favorites: [], 
      filterTimeout: null,
    }
  },
  methods: {
    toggleSearch() {
        this.mode_search = !this.mode_search
        this.selectedSubCat = null;
        this.submenu = 'catalog'

        /*if (this.mode_search) {
            console.log(this.$refs);
           //this.$refs.search.focus();

           this.$nextTick(() => {
            console.log(this.$refs);
            this.$refs.search_input.focus();
            })
        }*/

        this.$.emit('product-search');
    },
    changeFilter() {
      this.$.emit('product-filter', this.search_filter)
    },
    categoriesShow(shop) {

      this.selectedShop = shop;

      Net.fetch('categories', {method: 'GET', shop: this.selectedShop.id }).then((jsonData) => {
        console.log(jsonData);
        this.categories = jsonData;
        this.categories.forEach((cat) => {
          cat.child = cat.child.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
        this.submenu = 'categories';
      })
    },
    catalogSelect(cat) {

      this.mode_search = false;

      Net.fetch('categories/'+cat.id, {method: 'GET', shop: this.selectedShop.id}).then((jsonData) => {
        console.log(jsonData);

        this.subCategories = jsonData;
        this.subCategories.child = this.subCategories.child.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.submenu = 'catalog';
        
        this.subCategorySelect(this.subCategories.child[0]);
      })

      console.log(cat);
    },
    subCategorySelect(subCat) {

      console.log(subCat);
      this.selectedSubCat = subCat;

      Net.fetch('products', {method: 'GET', shop: this.selectedShop.id, category: subCat.id}).then((jsonData) => {
        console.log(jsonData);
        this.products = jsonData.results;
      })
    },
    productSelect(products) {

      console.log(products)

      this.$.emit('catalog-callback', products)

      //PageRecipeContent.methods.productAddEvent(product)

      /*if (this.mainPageReturnProduct) {

        this.mainPageReturnProduct = false;   
        this.catalogCallback(product);       
      } else {
        this.productDialog.product = product;
        this.productDialog.show = true;
      }*/
      },

      productSearch() {
      this.mode_search = true;
      //this.subCategories = null;
      this.selectedPage = "catalog";
      this.selectedSubCat = null;
    },

    

    productFilter(productName) {
      
      let filter = {name: productName}

      if (this.selectedSubCat) {
        filter.category = this.selectedSubCat.id;
      }

      if (this.filterTimeout) {
        window.clearTimeout(this.filterTimeout);          
      }

      this.filterTimeout = window.setTimeout(() => {

          Net.fetch("products", {method: 'GET', ...filter}).then((jsonData) => {
            this.products = jsonData.results;
          })

          this.filterTimeout = null;
        }, 800);
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.shop {
  background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
}


.search_input {
  border: none;
  display: inline-flex;
  flex-grow: 4;
}
</style>
