<template>
  <v-card>

    <v-toolbar density="compact"  color="green-lighten-4"  >
        <v-btn icon @click="$.emit('catalog-exit')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!mode_search" @click="$.emit('toggle-mode')">Найти продукт</v-toolbar-title>

        <v-text-field density="compact" hide-details v-if="mode_search" ref="search_input" v-model="search_filter" @keyup="changeFilter">

        </v-text-field>


        <v-btn icon @click="$.emit('toggle-mode')">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon :disabled="getOkStatus()" @click="commitMultiSelect">
          <v-icon>mdi-check-bold</v-icon>
        </v-btn>

        <template v-slot:extension v-if="subCategories">
           
             <v-tabs align-with-title>
                <v-tab class="text-caption" color="green" v-for="item in subCategories.child" :key="item.id" @click="selectSubCategory(item)">
                    {{ item.name }}
                </v-tab>
            </v-tabs>
        
        </template>

  

    <v-navigation-drawer
        v-model="drawer"
        temporary  
      >
        <v-list density="compact" nav >
          <v-list-subheader>Рецепты</v-list-subheader>
          <v-list-item :title="recp.name" :value="recp" v-for="recp in recipes" :key="recp.id" @click.stop="pushToRecp(recp)">
            <template>
              <v-list-item-title>{{ recp.name }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
        v-model="list_drawer"
        temporary  
      >
        <v-list density="compact" nav >
          <v-list-subheader>Покупки</v-list-subheader>
          <v-list-item :title="recp.name" :value="recp" v-for="recp in buylist" :key="recp.id" @click.stop="pushToList(recp)">
            <template>
              <v-list-item-title>{{ recp.name }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>

  </v-toolbar>


    <v-container style="height: 100%; overflow-y: scroll">
       
            <v-row align="start">
                <v-col v-for="product in products" :key="product.id" cols="6" class="pa-1">
                <v-card :class="weightIsSet(product)" style="aspect-ratio: 11/13" @click="selectProduct(product)">
                    
                  <v-card-text>
                        {{ product.name }}
                    </v-card-text>
                    <v-card-title>
                        {{ product.price }}₽
                    </v-card-title>
                    
                    <v-card-subtitle>
                        {{ getPrice100(product) }}
                    </v-card-subtitle>

                    <v-card-actions v-if="multiselect">
                      <v-btn size="small" color="surface-variant" :variant="getToggleStatus(product)" @click.stop="(ev) => toggleSelect(ev, product)">Выбрать</v-btn>                      
                    </v-card-actions>

                    <v-card-actions v-else>
                      <v-btn size="small" color="surface-variant" variant="text" :icon="getFav(product)"  @click.stop="(ev) => toggleFav(ev, product)"></v-btn>
                      <v-btn size="small" color="surface-variant" variant="text" icon="mdi-bookmark-multiple-outline"  @click.stop="togglePushDrawer(product)"></v-btn>
                      <v-btn size="small" color="surface-variant" variant="text" icon="mdi-format-list-checkbox" @click.stop="toggleListDrawer(product)"></v-btn>
                    </v-card-actions>
                    
                </v-card>
                </v-col>
            </v-row>
      
          </v-container>
  
  </v-card>
</template>

<script>
export default {
  name: 'PageCatalog',
  props: {
    subCategories: Object,
    products: Array,
    recipes: Array,
    favorites: Array,
    return_product: Boolean,
    mode_search: Boolean,
    buylist: Array,
    inline: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        selectedCat: null,
        fav: [],
        drawer: false,
        push_product: null,
        list_drawer: false,
        list_multi: []
    }
  },
  watch: {
    favorites(newVal) {
      this.fav = newVal.map((item) => {
        return item.id;
      });
    }
  },
  methods: {
    getPrice100(product) {
      if (product.unit == 'шт' && product.weight)
        return parseFloat(product.price / product.weight * 100).toFixed(2) + '₽ за 100 гр';
      if (product.unit == 'кг')
        return parseFloat(product.price / 10).toFixed(2) + '₽ за 100 гр';
      if (product.unit == '100г')
        return product.price + '₽ за 100 гр';

      return `unit = '${product.unit}'`;
    },
    weightIsSet(prod) {
        if (prod.unit == 'nan')
            return {"bg-red-lighten-4": true}
    },
    toggleFav(ev, product) {
      ev.stopPropagation();
      this.$.emit('product-favorite', product, this.fav.includes(product.id))
    },
    toggleSelect(ev, product) {
     //console.log(ev, product)
      if (this.list_multi.includes(product)) {
        this.list_multi.splice(this.list_multi.indexOf(product), 1);
      } else {
        this.list_multi.push(product);
      }
    },
    getToggleStatus(product) {
      if (this.list_multi.includes(product)) {
        return "flat"
      }
      return "text"
    },
    getOkStatus() {
      return  (this.list_multi.length == 0);
    },
    togglePushDrawer(product) {
      this.push_product = product;
      this.drawer = !this.drawer;
    },
    toggleListDrawer(product) {
      this.push_product = product;
      this.list_drawer = !this.list_drawer;
    },
    pushToRecp(recp) {
      this.$.emit('product-push-recipe', recp, this.push_product);
    },
    pushToList(list) {
      this.$.emit('product-push-buylist', list, this.push_product);
    },
    getFav(product) {
      if (this.fav.includes(product.id)) {
        return "mdi-heart";
      } else {
        return "mdi-heart-outline";
      }

    },
    getBgImage() {
        //console.log(product);

        return 'https://cdn-img.perekrestok.ru/i/800x800-fit/xdelivery/files/d6/37/edf37a0dd44307ab02c48eb29737.jpg';

    },
    selectSubCategory(cat) {
        this.$.emit('catalog-select', cat)
    },
    selectProduct(product) {
      if (!this.multiselect)
        this.$.emit('product-select', [product]);
    },
    changeFilter() {
      this.$.emit('product-filter', this.search_filter)
    },
    commitMultiSelect() {
      this.$.emit('product-select', this.list_multi);
    }
  },
  mounted() {
    this.selectedCat = this.products[0];
    this.fav = this.favorites.map((item) => {
      return item.id;
    });

    if(this.mode_search) {
      this.$refs.search_input.focus();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.category {
    
    margin-left: 5px;
    margin-right: 5px;
   
}

.v-card-text {
  height: 75px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

</style>
