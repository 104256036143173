<template>
 
    <div class="search regular_input" @click="$.emit('catalog-exit')">
      <div class="search_icon"></div>
      <span>Найти продукт1</span>
    </div>
          
    <div class="search" >
        <div class="categories">
            <div class="category" v-for="item in subCategories.child" :key="item.id" @click="$.emit('catalog-select', item)">
                {{ item.name }}
            </div>
        </div>
    </div>

    <div class="shops">
      <div class="product" v-for="product in products" :key="product.id" @click="$.emit('product-show', product)" :style="{backgroundImage: getBgImage(product)}">
        <div class="price">{{ product.price }}</div>
        <div class="name">{{ product.name }}</div>
      </div>
    
    </div>
    
    
 
</template>

<script>
export default {
  name: 'PageCatalog',
  props: {
    subCategories: Object,
    products: Array
  },
  methods: {
    getBgImage() {
        //console.log(product);

        return 'url(https://cdn-img.perekrestok.ru/i/800x800-fit/xdelivery/files/d6/37/edf37a0dd44307ab02c48eb29737.jpg)';

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.categories {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-bottom: 3vmin;
  height: 100%;

}

.categories::-webkit-scrollbar {
    
}


.product {
  display: block;
  /*eight: 30vh;*/
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% auto;
  border-radius: 2vmin;
  margin: 2vmin;
  padding: 2vmin;

  color: green;
  font-size: 4vmin;
}

.product .price {
    margin-top: 40vw;
    
}

.product .name {
    
}

.search {
  display: flex;
  height: 5vh;
  align-items: center;

  
  
}

.search span {
  flex-grow: 4;
  text-align: left;
  color: gray;
}

.search_icon {
  border: none;
  height: 4vh;;
  flex-grow: 1;
  background-image: url(@/assets/images/menu_search.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.search_input {
  border: none;
  display: inline-flex;
  flex-grow: 4;
}

.category {
    
    background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
    border-radius: 3vmin;
    margin: 1vmin 2vmin;;
    padding: 2vmin;
    white-space: nowrap;

    margin-bottom: 10px;
   
}

.header {
    display: inline-flex;
    
    font-size: 5vmin;
    margin: 1vmin 2vmin;;
    padding: 2vmin;
}


.shops {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  flex-grow: 7;
  overflow-y: scroll;
}

.shop {
  display: block;
  
  
  height: 20vh;

  background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
  border-radius: 2vmin;
  margin: 2vmin;
  padding: 2vmin;

  color: green;
  font-size: 3vh;

  
}
</style>
