<template>

    <v-app-bar density="compact" color="var(--base-color)">
          <v-btn icon @click="doExit(0)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
  
          <v-app-bar-title v-if="!header_search">Расчет БЖУК</v-app-bar-title>
          <v-btn icon @click="toggleSearch">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-app-bar>
  
    <v-main>
  
  
      
    <v-card>
             <!-- <v-list-group :value="String(index)" @click="opened = [String(index)]" >-->
                <v-card-title>
                <v-select :items="innerUsers" item-title="name" item-value="index" v-model="currentUserName" @update:modelValue="changeUser"> 
                </v-select>
            </v-card-title>

                  <v-table density="compact" class="head" v-if="currentUser">
                      <thead>
                      <tr>
                   
                          <th class="text-center text-subtitle-2">
                          Б
                          </th>   
                          <th class="text-center text-subtitle-2">
                          Ж
                          </th> 
                          <th class="text-center text-subtitle-2">
                          У
                          </th>                  
                          <th class="text-center text-subtitle-2">
                          Ккал
                          </th>


                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-orange-darken-3">

                          <td class="text-center text-subtitle-2">
                            {{ getParam('b') }}
                          </td>
                          <td class="text-center text-subtitle-2">
                            {{ getParam('g') }}
                          </td>
                          <td class="text-center text-subtitle-2">
                            {{ getParam('u') }}
                          </td>

                          <td class="text-center text-subtitle-2">
                          {{ getParam('k') }}
                          </td>

                      </tr>
                      
                      </tbody>
                  </v-table>

                <v-table density="compact" class="head" v-if="currentUser">

                      <tbody>
                        <tr>
                            <td class="text-left text-subtitle-2 ">
                                Имя
                            </td>
                            <td class="text-lefttext-subtitle-2 pa-2">
                                <v-text-field v-model="currentUser.name" density="compact" hide-details="true" @update:modelValue="setChangedState">
                                </v-text-field> 
                            </td>
                        </tr>

                        <tr>
                            <td class="text-left text-subtitle-2 ">
                                Пол
                            </td>
                            <td class="text-lefttext-subtitle-2 pa-2">
                                <v-select density="compact" hide-details="true" v-model="currentUser.sex" :items="[{title: 'мужской', value: 1}, {title: 'женский', value: 2}]"  @update:modelValue="setChangedState">
                                </v-select> 
                            </td>
                        </tr>

                        <tr>
                            <td class="text-left text-subtitle-2">
                                Вес, кг
                            </td> 
                            <td class="text-left text-subtitle-2 pa-2" >
                                <v-text-field v-model="currentUser.weight" density="compact" hide-details="true"  @update:modelValue="setChangedState">
                                </v-text-field> 
                            </td>
                        </tr>

                        <tr>
                            <td class="text-left text-subtitle-2">
                                Рост, см
                            </td> 
                            <td class="text-left text-subtitle-2 pa-2">
                                <v-text-field v-model="currentUser.height" density="compact" hide-details="true"  @update:modelValue="setChangedState">
                                </v-text-field> 
                            </td>
                        </tr>


                        <tr >
                            <td class="text-left text-subtitle-2">
                                Возраст, лет
                            </td>
                          <td class="text-left text-subtitle-2 pa-2">
                            <v-text-field v-model="currentUser.age" density="compact" hide-details="true"  @update:modelValue="setChangedState">
                            </v-text-field>  
                          </td>

                      </tr>

                      <tr >
                            <td class="text-left text-subtitle-2">
                                Активность
                            </td>
 
                          <td class="text-lefttext-subtitle-2 pa-2">
                                <v-select density="compact" hide-details="true" v-model="currentUser.lifestyle"  @update:modelValue="setChangedState"
                                    :items="[
                                        {title: 'Малоактивный образ жизни (мало или совсем нет занятий спортом)', value:1}, 
                                        {title: 'Легкая активность (несложные упражнения / спорт 1–3 дня в неделю)', value: 2},
                                        {title: 'Умеренная активность (умеренные упражнения / спорт 3-5 раз в неделю)', value: 3},
                                        {title: 'Активный (тяжелые упражнения / спорт 6-7 почти ежедневно)', value: 4},
                                        {title: 'Повышенная активность (очень тяжелые упражнения / спорт и физическая работа)', value: 5},
                                    ]">
                                </v-select> 
                            </td>

                      </tr>

                      <tr >
                            <td class="text-left text-subtitle-2">
                                Цель
                            </td>

                          <td class="text-lefttext-subtitle-2 pa-2">
                            <v-select density="compact" hide-details="true" v-model="currentUser.target" 
                                    :items="[
                                        {title: 'Увеличение мышечной массы', value: 1}, 
                                        {title: 'Поддержание формы/выносливость', value: 2},
                                        {title: 'Похудение/сушка', value: 3},
                                        {title: 'Ручной ввод ссотношения  Б/Ж/У', value: 4},                                        
                                    ]" @update:modelValue="changeTarget(index)">
                                </v-select> 
                            </td>

                      </tr>

                      <tr>
                        <td class="text-left text-subtitle-2">
                                Соотношение<br/> Б/Ж/У, %
                            </td>
                        <td v-if="currentUser.target != 4" class="text-lefttext-subtitle-2 pa-2">

                            {{ currentUser.protein }} / {{ currentUser.fat }} / {{ currentUser.carbohydrates }}

                        </td>
                        <td v-else valign="middle" style="vertical-align: middle; " class="text-lefttext-subtitle-2 pa-2">
                            <v-text-field v-model="currentUser.protein" density="compact" hide-details="true" class="short" @update:modelValue="changeTargetBGU(index, 0)">
                            </v-text-field>   / 
                            <v-text-field v-model="currentUser.fat" density="compact" hide-details="true"  class="short" @update:modelValue="changeTargetBGU(index, 1)">
                            </v-text-field>   / 
                            {{ currentUser.carbohydrates }}
                        </td>
                      </tr>
                      
                      </tbody>
                  </v-table>

  
                </v-card>
      <v-dialog v-model="dialog" persistent width="auto">
          <v-card>
              <v-card-text>Сохранить настройки перед выходом?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="doExit(-1)">
                  Не сохранять
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="doExit(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_weight" persistent width="auto">
          <v-card>
              <v-card-text>Укажите вес продукта</v-card-text>
              <v-card-text>
                  <v-text-field density="compact" hide-details="true" v-model="prod_weight">
  
                      <template v-slot:prepend>
                          <v-icon icon="mdi-minus-circle" @click="increaseWeight(-50)" />
                      </template>    
  
                      <template v-slot:append>
                          <v-icon icon="mdi-plus-circle" @click="increaseWeight(50)" />
                      </template>    
  
                      
                  </v-text-field>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="red-darken-1"  variant="text" @click="weightDialog(-1)">
                  Закрыть
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="weightDialog(1)">
                  Сохранить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
      <v-dialog v-model="dialog_remove" persistent width="auto">
          <v-card>
              <v-card-text>Удалить продукт из рецепта?</v-card-text>
              
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="green-darken-1"  variant="text" @click="removeDialog(-1)">
                  Отмена
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="removeDialog(1)">
                  Удалить
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-footer
    app
    fixed
    grow
    class="justify-center"
    color="transparent"
    v-if="changed"
  >

    <v-btn   color="green-darken-3" prepend-icon="mdi-content-save"  size="large" @click="doExit(2)">Сохранить</v-btn>
    
  </v-footer>
  
    </v-main>
  
  </template>
  
  <script>


  export default {
    name: 'PageManage',
    emits: ['recipe-exit', 'recipe-store', 'recipe-product-add'],
    props: {
      msg: String,
      recipe: Object,
      users: Array
    },
    data() {
      return {
          recipes: [1,2,3],
          header_search: false,
          internalRecipe: {portions: 1, items: []},
          dialog: false,
          dialog_weight: false,
          dialog_remove: false,
          prod_weight: 0,
          prod: null,
          variants: ['normal', 'edit'],
          variant: 'normal',
          changed: false,
          show_desc: false,
          opened: ["0"],
          innerUsers: [],
          energyBase: {
            '1':   [ 88.363, 13.398, 4.798, -5.678],
            '2': [447.594,  9.248, 3.099, -4.331],
          },
          currentUser: null,
          currentUserName: 0,
          activityBase: [
            0,
            1.2,
            1.375,
            1.55,
            1.725,
            1.9,
          ]
      }
    },
    methods: {
        toggleSearch() {
            this.$.emit("family-member-add");
        },
        changeUser() {
            this.currentUser = this.innerUsers[this.currentUserName];
        },
        changeTargetBGU() {
            this.setChangedState();

            //var sbgu = this.currentUser.bgu;
            this.currentUser.carbohydrates = 100 - this.currentUser.protein - this.currentUser.fat;
            //console.log(index, bgu)
        },
      changeTarget(index) {
        this.setChangedState();

        console.log('change', index)

        

        if (this.currentUser.target == 1) {
            
            this.currentUser.protein = 30;
            this.currentUser.fat = 30;
            this.currentUser.carbohydrates = 40;
        }
        if (this.currentUser.target == 2) {
            
            this.currentUser.protein = 25;
            this.currentUser.fat = 30;
            this.currentUser.carbohydrates = 45;
        }
        if (this.currentUser.target == 3) {
            
            this.currentUser.protein = 35;
            this.currentUser.fat = 30;
            this.currentUser.carbohydrates = 45;
        }
      },
      getParam(type) {

        //console.log(this.currentUser)

        if (type == 'k') {
            var base = this.energyBase[this.currentUser.sex];
            return (this.activityBase[this.currentUser.lifestyle] * (base[0] + base[1]*this.currentUser.weight + base[2]*this.currentUser.height + base[3]*this.currentUser.age)).toFixed(0);
        }

        if (type == 'u') {
            return (this.getParam('k') * this.currentUser.carbohydrates / 100 / 4).toFixed(0)
        }

        if (type == 'b') {
            return (this.getParam('k') * this.currentUser.protein / 100 / 4).toFixed(0)
        }

        if (type == 'g') {
            return (this.getParam('k') * this.currentUser.fat / 100 / 9).toFixed(0)
        }

      },
      setChangedState() {
          this.changed = true;
      },
      getSubtitle(prod) {
          return "<table><tr><td>" + 
              (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") + "</td><td>" +                       
              (prod._it.fats ? this.getVal(prod._it.fats) : "0") + "</td><td>" +            
              (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") + "</td></tr></table>";
      },
      toggleHeader() {
          this.header_search = !this.header_search
      },
      increase(val, obj, field) {
  
          console.log(obj[field])
          this.internalRecipe.changed = true;
  
          if (obj[field]) {
  
              if (obj[field] + val > 0)
                  obj[field] = parseFloat(obj[field]) + val;
          } 
  
      },
      weightIsSet(prod) {
          if (prod._it.unit == 'nan')
              return {"bg-red-lighten-4": true}
      },
      increaseWeight(val) {
          if (val < 0 && this.prod_weight >= -val)
              this.prod_weight = parseFloat(this.prod_weight) + val;
          if (val > 0)
              this.prod_weight = parseFloat(this.prod_weight) + val ;
      },
      weightDialog(num, prod) {
          if (num == 0) {
              this.prod_weight = prod.quantity;
              this.prod = prod;
              this.dialog_weight = true;
          }
  
          if (num == -1)
              this.dialog_weight = false;
  
          if (num == 1) {
              this.prod.quantity = this.prod_weight;
              this.dialog_weight = false;
              this.internalRecipe.changed = true;
              //this.$.emit('recipe-store', this.internalRecipe);
          }
      },
      removeDialog(num, prod) {
          if (num == 0) {
              this.prod = prod;
              this.dialog_remove = true;
          }
  
          if (num == -1)
              this.dialog_remove = false;
  
          if (num == 1) {
              
              this.dialog_remove = false;
              this.internalRecipe.changed = true;
              
              this.internalRecipe.items = this.internalRecipe.items.filter((item) => {
                  return item.product != this.prod.product;
              })
          }
      },
      doExit(num) {
          if (num == 0) {
              if (this.changed)
                  this.dialog = true;
              else
                  this.$.emit('exit');
          }
  
          if (num == -1)
              this.$.emit('exit');
  
          if (num == 1) {
              this.$.emit('store', this.innerUsers, true);
          }
  
          if (num == 2) {
              this.changed = false;
              this.$.emit('store', this.innerUsers, true);
              
          }
      }, 
      productAddEvent(product) {
          console.log("Product add", product)
           console.log(this.internalRecipe);
          //this.internalRecipe.items.push(product);
      },
      remove(key) {
          console.log(key);
          
          this.internalRecipe.items.splice(key, 1);
          
      },
      getVal(val, fixed=0) {
  
          
          if (!isNaN(val)) {
              return parseFloat(val).toFixed(fixed);
          } else {
              return parseFloat("0").toFixed(fixed);
          }
      },
      getSum(type) {
  
          var res = 0.0;
          var types = {'u': 'carbohydrates', 'g': 'fats', 'b': 'proteins', 'e': 'energy', 'w': 'weight', 'q': 'quantity'};
  
          //console.log((this.internalRecipe.items));
  
          if (Object.keys(types).includes(type)) {
  
              if (this.internalRecipe.items) {
  
                  this.internalRecipe.items.forEach((item) => {
  
                      if (item[types[type]]) {
                          res += parseFloat(item[types[type]])
                      }
  
                      if (item._it && item._it[types[type]] && type == 'p' && item._it.weight) {
                          res += parseFloat(item._it.price) / parseFloat(item._it.weight)
                          return;
                      }
  
                      if (item._it && item._it[types[type]])
                          res += parseFloat(item._it[types[type]]) * parseFloat(item.quantity) / 100
                  });
  
              }
          }
  
          //console.log(res.toFixed(1) * 100);
  
          return res.toFixed(2);
      },
      getPrice() {
  
          var res = 0.0;
          
  
          //console.log((this.internalRecipe.items));
  
          if (this.internalRecipe.items) {
  
              this.internalRecipe.items.forEach((item) => {
  
                  if (item._it && item._it.unit == '100г') {
                      res += item.quantity * parseFloat(item._it.price) / 100
                      return;
                  }
  
                  if (item._it && item._it.unit == 'кг') {
                      res += item.quantity * parseFloat(item._it.price) / 1000
                      return;
                  }
  
                  if (item._it && item._it.unit == 'шт' && item._it.weight) {
                      res += item.quantity * parseFloat(item._it.price) / parseFloat(item._it.weight)
                      return;
                  }
              });
  
          }
          
  
          //console.log(res.toFixed(1) * 100);
  
          return res.toFixed(2);
      },
      toggleMode() {
          if (this.variant == 'normal')
              this.variant = 'edit';
          else 
              this.variant = 'normal';
  
          
  
      }
    },
    mounted() {
  
        console.log(this.users)
  
        this.innerUsers = this.users.map((u) => {
            return Object.assign({}, u)
        })
        this.innerUsers.forEach((user, index) => {
            user.index = index;
        })
        
        this.currentUser = this.innerUsers[0];
        this.currentUserName = 0;
  
  
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .v-col {
      padding-top: 0;
      padding-bottom: 0;
  }
  .v-list-subheader {
      padding-top: 0;
  }
  
  .count {
      min-width: 130px;
  }
  
  tr.hidden {
      display: none;
  }

  .short {
    width: 55px;
    display: inline-block;
  }
  
  .absolute {
      position: fixed;
      margin: 0 12%;
      bottom: 6%;
      
      z-index: 9999;
      width: 74%;
      
  }
  
  .head {
      padding: 0 8px;
  }
  
  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
      padding: 0 8px;
  }
  </style>
  