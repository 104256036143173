<template>

    <v-app>

      <Transition > 
        
        <PageSplash v-if="pageIsSelected('splash')" />
        
        <PageMain 
   
            @logout="logout"
            @configure-users="selectedPage = 'manage'"
            @plan-users="selectedPage = 'plan'"
            @navigate="(page) => {selectedPage = page}"

            :shops="shops"
            :return_product="mainPageReturnProduct" 
            v-else-if="pageIsSelected('main')" 
        />  

        <PageStocks 
   
            @logout="logout"
            @configure-users="selectedPage = 'manage'"
            @plan-users="selectedPage = 'plan'"
            @stocks="selectedPage = 'stocks'"
            @exit="selectedPage = 'main'"

            :shops="shops"
            :return_product="mainPageReturnProduct" 
            v-else-if="pageIsSelected('stocks')" 
        />  

        <PageManage
            :users="familyMembers"
            @exit="selectedPage = 'main'"
            @store="familyMembersStore"
            @family-member-add="familyMemeberAdd"

            v-else-if="pageIsSelected('manage')" 
        />  

        <PageRecipes 
            @recipe-create="recipeShow(null)"
            @recipe-show="recipeShow"
            @recipe-delete="recipeDelete"

            :recipes="recipes"
            v-else-if="pageIsSelected('recipes')"
        />

        <PageSwap
            :product="currentSwap"
            :recipe="currentRecipe" 
            @swap-product-add="(payload, callback) => { catalogSelectProducts(payload, recipeProductsAdd, callback) }"
            @swap-exit="swapExit"
            @swap-confirm="swapConfirm"
            @swap-store="swapStore"
            :users="familyMembers"

            v-else-if="pageIsSelected('swap')"
        />

        <PagePlan
            
            
            :users="familyMembers"
            :plan="plan"
            
            @recipe-exit="selectedPage = 'main'"
            @product-add="(payload, callback) => { catalogSelectProducts(payload, planProductAdd, callback) }"
            @recipe-add="(payload, callback) => { listSelectRecipe(payload, planProductAdd, callback) }"
            @recipe-store="storePlan"

            v-else-if="pageIsSelected('plan')"
        />

        <PageBuyList 
            @buylist-create="buylistShow(null)"
            @buylist-show="buylistShow"
            @buylist-delete="buylistDelete"

            :buylist="buylist"
            v-else-if="pageIsSelected('buylist')"
        />

        <PageProductExec 
            @buylist-create="buylistShow(null)"
            @buylist-show="buylistShow"
            @buylist-delete="buylistDelete"
            @navigate="(page) => {selectedPage = page}"
            :users="users"

            :buylist="buylist"
            v-else-if="pageIsSelected('productexec')"
        />

        <PageProductGen 
            @buylist-create="buylistShow(null)"
            @buylist-show="buylistShow"
            @buylist-delete="buylistDelete"
            @navigate="(page) => {selectedPage = page}"

            :buylist="buylist"
            :users="users"
            v-else-if="pageIsSelected('productgen')"
        />

        <PageFavorites 

            @product-favorite="productFavorite"
            @product-push-recipe="recipePush"
            @product-push-buylist="buylistPush"

            @favorite-add="favoriteAdd"


            :favorites="favorites"
            :recipes="recipes"
            :buylist="buylist"

            v-else-if="pageIsSelected('favorite')"
        />
        
        

          <PageRecipeContent 
            @recipe-exit="recipeExit"
            @recipe-store="recipeStore"
            @recipe-product-add="(payload, callback) => { catalogSelectProducts(payload, recipeProductsAdd, callback) }"
            @product-swap="productSwap"

            :recipe="currentRecipe"            

            v-else-if="pageIsSelected('recipe')"
          />

          <PageBuyListContent 
            @buylist-exit="buylistExit"
            @buylist-store="buylistStore"
            @buylist-product-add="(payload, callback) => { catalogSelectProducts(payload, buylistProductAdd, callback) }"
           
            :buylist="currentBuyList"            

            v-else-if="pageIsSelected('buylist_content')"
          />

          <PageLogin 
            @login-continue="loginContinue" 
            @login-getcode="loginGetCodeWatch" 
            
            class="wrapped_item" 
            
            :state_getcode="state_getcode"
            :auth_error="auth_error"
            :pincode="pincode"

            v-else-if="pageIsSelected('login')"
          />

      </Transition>


    <PageProduct v-if="pageIsSelected('product')"/>

    <BottomMenu
      @select-menu-item="selectMenuItem"
      
      :menu_items="menu_items" 
      :selected_page="selectedPage"

      
      v-if="pageIsSelected('bottom_menu')"
    />

    <v-bottom-sheet v-model="show_cat_bottom" height="80%" class="cat_bottom">

      
      <FrameTitul 
            
            @submenu-close="show_cat_bottom = false"
            @catalog-callback="catalogCallback"

            :shops="shops"
            :return_product="mainPageReturnProduct"
            :multiselect="true" 
                  
        />  
     
    </v-bottom-sheet>

    <v-bottom-sheet v-model="show_list_bottom" height="80%" class="cat_bottom">
      <FrameListRecipes
          
          @submenu-close="show_list_bottom = false"
          @catalog-callback="catalogCallback"

          :recipes="recipes"
          :return_product="mainPageReturnProduct" 
      />  
    </v-bottom-sheet>

    

  </v-app>

</template>

<script>
import PageSplash from './components/PageSplash.vue'
import PageMain from './components/PageMain.vue'
import PageRecipes from './components/PageRecipes.vue'
import PageRecipeContent from './components/PageRecipeContent.vue'
import PageProduct from './components/PageProduct.vue'
import PageLogin from './components/PageLogin.vue'
import PageFavorites from './components/PageFavorites.vue'
import PageManage from './components/PageManage.vue'
import PageBuyList from './components/PageBuyList.vue'
import PageStocks from './components/PageStocks.vue'
import PageBuyListContent from './components/PageBuyListContent.vue'
import PageSwap from './components/PageSwap.vue'
import PagePlan from './components/PagePlan.vue'
import FrameTitul from './components/FrameTitul.vue'
import FrameListRecipes from './components/FrameListRecipes.vue'
import PageProductExec from './components/PageProductExec.vue'
import PageProductGen from './components/PageProductGen.vue'

import BottomMenu from './components/BottomMenu.vue'

import Net from "./common.js"
import RecipeMethods from "./recipeMethods.js"
import BuyListMethods from "./buylistMethods.js"
import FamilyMethods from "./familyMethods.js"
import SwapMethods from "./swapMethods.js"

Net.registerWorker();

export default {
  name: 'App',

  components: {
    PageSplash,
    PageMain,
    PageManage,
    PageRecipes,
    PageRecipeContent,
    PageLogin,
    PageProduct,
    PageFavorites,
    PageBuyList,
    PageBuyListContent,
    BottomMenu,
    PageSwap,
    PagePlan,
    FrameTitul,
    FrameListRecipes,
    PageStocks,
    PageProductExec,
    PageProductGen
  },

  data() {
    return {
      menu_items: [
          { name: "main", title: 'Главная', mdi: 'mdi-home-outline', icon: require('@/assets/images/menu_home.svg') },
          { name: "buylist", title: 'Покупки', mdi: 'mdi-format-list-checkbox', icon: require('@/assets/images/menu_purchases.svg') },
          { name: "favorite", title: 'Избранное', mdi: 'mdi-heart', icon: require('@/assets/images/menu_fav.svg') },
          { name: "recipes", title: 'Рецепты', mdi: 'mdi-bookmark-multiple-outline', icon: require('@/assets/images/menu_recipes.svg') }
      ],
      show_cat_bottom: false,
      show_list_bottom: false,
      safeInsetTop: 0,
      selectedPage: 'splash',
      state_getcode: false,
      shops: [],
      categories: [],
      
      subCategories: null,
      
      
      users: [{
        name: "Пользователь 1",
        sex: "male",
        weight: 102,
        age: 35,
        height: 179,
        activity: 0,
        target: 2,
        bgu: [30, 30, 40]
      },
      {
        name: "Пользователь 2",
        sex: "male",
        weight: 78,
        age: 32,
        height: 182,
        activity: 3,
        target: 1,
        bgu: [30, 30, 40]
      }],
      plan: [
          { quantity:100, _it: {
              "id": 735,
              "name": "Соль Setra Морская крупная йодированная 500г",
              "article_number": "305045",
              "category_full_string": "Каталог/Макароны, крупы, специи/Соль",
              "price": "57.90",
              "old_price": "0.00",
              "in_stock": true,
              "unit": "шт",
              "proteins": null,
              "fats": null,
              "carbohydrates": null,
              "energy": null,
              "composition": "Морская соль садочная, йодат калия",
              "brand": "Setra",
              "manufacturer": "Медитеран",
              "weight": 500,
              "date_created": "2023-01-23T00:50:59.287822+03:00",
              "date_updated": "2019-10-04T19:11:00+03:00",
              "description": "Соль Setra Natural Sea Salt морская крупная йодированная 500г - полезная альтернатива поваренной соли. Она добывается в Словении, в акватории Адриатического моря. Производится путем выпаривания морской воды под воздействием природных факторов: ветра и солнца. Специальные бассейны с морскими водорослями на дне защищают кристаллы соли от грязи и помогают сохранить ценный минеральный состав полностью. Обогащена йодатом калия. Крупная соль лучше всего подходит для приготовления горячих блюд, супов, она прекрасно растворяется в кипящей воде. Ее можно использовать для засолки рыбы и консервирования овощей. Упакована в картонную коробку, легко открывается, экономичный объем.",
              "product_link": "https://www.perekrestok.ru/catalog/makarony-krupy-spetsii/sol/setra-sol-morskaya-pisch-yodir-krup-500g--305045",
              "pictures": "https://www.perekrestok.ruhttps://static02.perekrestok.ru/src/product.file/big/image/42/19/41942.jpeg,https://www.perekrestok.ruhttps://static03.perekrestok.ru/src/product.file/big/image/43/19/41943.jpeg",
              "shop": 1,
              "category": 36,
              "region": 1
          }},
          
          
      ],
      recipes: [],
      buylist: [],
      
      currentRecipe: null,
      currentBuyList: null,
      selectedCat: null,
      currentSwap: null,
      interval: null,
      auth_error: '',
      pincode: '',
      mainPageReturnProduct: false,
      
      
      familyMembers: [],
      catalogCallback: null,
      
    }
  },
  methods: {
    ...RecipeMethods,
    ...BuyListMethods,
    ...FamilyMethods,
    ...SwapMethods,

    selectMenuItem(item) {
      console.log(item);
      this.selectedPage = item.name;
    },
    pageIsSelected(pageName) {

      if (pageName == 'bottom_menu') {
        
        let res = false;

        let major = this.menu_items.map((item) => { return item.name });

        if (major.indexOf(this.selectedPage) >=0 )
          res = true;
        
        return res;

      }

      return this.selectedPage == pageName;
    },
    
    catalogSelectProducts(payload, callback/*, callback2*/) {

      /*this.selectedPage = "main";
      this.mainPageReturnProduct = true;*/

      this.catalogCallback = (item) => {
        this.show_cat_bottom = false;
        callback(payload, item)
      }     

      this.show_cat_bottom = true;

    },

    listSelectRecipe(payload, callback/*, callback2*/) {

      /*this.selectedPage = "main";
      this.mainPageReturnProduct = true;*/

      this.catalogCallback = (item) => {

        
        this.show_list_bottom = false;

        this.recipeLoadProducts(item.items).then(() => {
          console.log("PAYLOAD", payload, callback, item)

          this.recipeGetParams(item)
/*
          item.proteins = Net.getSum(item.items, 'b', item.portions);
          item.fats = Net.getSum(item.items, 'g', item.portions);
          item.carbohydrates = Net.getSum(item.items, 'u', item.portions);
          item.energy = Net.getSum(item.items, 'e', item.portions);
          item.weight = Net.getSum(item.items, 'q');
          item.price = Net.getPrice(item.items);
          item.unit = 'шт'*/

          callback(payload, item, "recipe")
        })

        
      }     

      this.show_list_bottom = true;

    },

    productFavorite(product, state) {

      if (state) {

        Net.fetch('products/' + product.id + '/favorites', {method: 'DELETE'}).then(this.favoritesGet)
      } else {
  
        Net.fetch('products/' + product.id + '/favorites', {method: 'POST'}).then(this.favoritesGet)
      }
    },

    planProductAdd(payload, product, type = "product") {

      console.log("ADD", payload ,product)

      if (product instanceof Array) {
          product.forEach((p) => {
            payload.items.push({
            quantity: type == "recipe" ? 1 : 100,
            type: type,
            _it: p
          }); 
        })        
      
      } else {
        payload.items.push({
        quantity: type == "recipe" ? 1 : 100,
        type: type,
        _it: product
      }); 
      }

       
      
      payload.changed = true;
    },

    loginContinue(phone, code) {

      console.log(phone, code);
      this.auth_error = "";
  
      Net.fetch('sms_auth/token/login', {phone: phone, code: code}).then((jsonData) => {
        console.log(jsonData);
        //localStorage.authToken = jsonData.auth_token;
        Net.setToken(jsonData.token);
        location.reload(true);
        
      }).catch(() => {
        console.log('Auth error!');
        this.auth_error = "Ошибка входа";
      })
    },

    loginGetCodeWatch(phone) {

      //this.loginGetCode(phone);
      this.auth_error = ''

      Net.fetch('sms_auth/sms_codes', {method: "GET"}).then((jsonData) => {
        var max = Math.max(...jsonData.results.map((item) => {
          return item.id;
        }));


        Net.fetch('sms_auth/get_sms_code', {phone: phone}).then(() => {
          
          this.interval = setInterval(() => {
            this.loginGetCode(phone, max)
          }, 1000);

          this.state_getcode = true;

        }).catch( (e) => {
            this.auth_error = 'Ошибка запроса: ' + e.message;
            //Net.notify("Debug", {body: JSON.stringify({ht: Net.hasToken(), text: await e.text()})})
        })

      });
    },

    loginGetCode(phone, max) {

      Net.fetch('sms_auth/sms_codes', {method: "GET"}).then((jsonData) => {
        
        jsonData.results.forEach((req) => {

          if (req.id>max && req.phone == phone) {

            Net.notify("Код подтверждения", {tag: 'Dobroed', body: req.code});
            clearInterval(this.interval);
            console.log(req.code);
            this.pincode = req.code;
          }
        })
      })

    },

    favoriteAdd() {

      this.catalogSelectProducts(null, (payload, item) => {
        console.log(item);
        this.productFavorite(item);
        
      });
    },

    


    categoriesExit() {
      this.selectedPage = 'main';
    },

    

    catalogExit() {
      if (!this.subCategories) {
        this.selectedPage = "main";
        this.selectedSubCat = null;
      } else {
        this.selectedPage = "categories";
      }

    },

    showSelectProductPopup(callback) {
      this.show_cat_bottom = true;
      this.catalogCallback = callback;
    },
    
    favoritesGet() {
      return Net.fetch('products/favorites', {method: 'GET'}).then((jsonData) => {
        console.log(jsonData);
        this.favorites = jsonData.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      });
    },

    marketsGet() {
      return Net.fetch('shops', {method: 'GET'}).then((jsonData) => {
        console.log(jsonData);
        this.shops = jsonData.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      });
    },

    authFail() {
      Net.clearToken();
      this.selectedPage = 'login';
    },

    logout() {
      Net.fetch('sms_auth/token/logout').then(() => {
        Net.clearToken();
        location.reload();
      }) 
    }
  },
  mounted() {    

    var insetTop = parseInt(document.documentElement.style.getPropertyValue('--android-safe-area-inset-top'));
    if (insetTop) {
      if (insetTop < 24)
        insetTop = 24
      this.safeInsetTop = insetTop
    }

    console.log("Top inset", this.safeInsetTop)

    if (Net.hasToken()) {

      Net.fetch('sms_auth/token/check', {method: "GET"}).then(() => {        

        Promise.all([
          this.marketsGet(),
          this.recipesGet(),
          this.favoritesGet(),
          this.buylistsGet(),
          this.getFamilyMembers(),
        ]).then(() => {
          console.log('ok')
          this.selectedPage = 'main';
        })
        
      }).catch((e) => {

        console.log(e);        
        this.authFail()
      })

    } else {
      this.authFail()
    }
  }
}
</script>

<style>

body .v-theme--light {
  /*--v-theme-on-surface: 235, 221, 140;*/
}

#app {
  --base-color:  #0d4b1d;
  --font-color: #5a5641;
  
  /* #e1cb4f #282828 #7d9764 */
}
</style>

<style striped>

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }

.v-card--product {
  height: 80%;
}

.list_composition .v-list-item-subtitle {
  display: block;
}

.product_list .v-card-title {
  white-space: unset;
}

.cat_bottom .v-card {
  height: 100%;
}


</style>

<style>
.absolute {
  position: absolute;
  width: 100%;
}
</style>
