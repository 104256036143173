import Net from "@/common.js"

export default {
    productSwap(prod, recipe) {
        console.log(prod, recipe)
        this.currentSwap = prod;
        this.selectedPage = 'swap'
    },
    swapExit() {
        console.log("exit")
        this.selectedPage = 'recipe'
    },
    swapConfirm(recipe, oldProduct, newProduct) {
        recipe.items.forEach((item) => {
            console.log(item)
            if (item._it.id == oldProduct._it.id) {
                item._it = newProduct._it;
                item.product = newProduct._it.id;

                recipe.changed = true;
            }


        })
        this.selectedPage = 'recipe';
    },
    swapStore(product, list) {

        console.log(product.analogs, list)

        product.analogs.filter((prod) => {
            return list.findIndex((l) => { return l.id == prod.id }) < 0
        }).forEach((rem) => {
            Net.fetch("products/" + product._it.id + "/analogs/" + rem.id, {method: 'DELETE'}).catch(() => { });
        })

        list.forEach((item) => {
            Net.fetch("products/" + product._it.id + "/analogs/" + item.id).catch(() => { });
        })
    }

}