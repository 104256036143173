<template>

    <v-main class="fill-height">
      
      <v-container class="pa-6 fill-height">
        <v-row   justify="center" align="center">
          <v-img :src="src" class="ma-12"/>
        </v-row>
      </v-container>
      
    </v-main>
    
</template>

<script>
export default {
  name: 'PageSplash',
  props: {
    msg: String,
    shops: Array
  },
  data() {
    return {
        src: require("@/assets/images/img_logo.svg")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-card {
  background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
}


.search_input {
  border: none;
  display: inline-flex;
  flex-grow: 4;
}
</style>
