<template>
  <v-card color="white">
   
      <v-toolbar density="compact"  color="green-lighten-4">
            <v-btn icon @click="$.emit('categories-exit')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title v-if="!search_mode">{{ shop.name }}</v-toolbar-title>
            <v-text-field variant="underlined" v-if="search_mode" v-model="search_filter" ref="search_input"></v-text-field>

            <v-btn icon>
              <v-icon @click="toggleSearch">mdi-magnify</v-icon>
            </v-btn>
      </v-toolbar>
 
    <v-container class="mb-6" v-for="cat in items" :key="cat.id"  style="height: 100%; overflow-y: scroll">
      
        <v-list density="compact">
          <v-list-subheader>{{ cat.name }}</v-list-subheader>
          <v-list-item v-for="item in filteredItems(cat.child, search_filter)" :key="item.id" @click="$.emit('catalog-select', item)">
            <v-list-item-title v-text="item.name"></v-list-item-title>

          </v-list-item>
        </v-list>
      
    </v-container>

  </v-card>
</template>

<script>
export default {
  name: 'PageCategories',
  props: {
    msg: String,
    items: Array,
    shop: Object
  },
  data() {
    return {
      search_mode: false,
      search_filter: ''
    }
  },
  methods: {
    toggleSearch() {
      this.search_mode = !this.search_mode;

      if (this.search_mode) {
        this.$nextTick(() => {
          console.log(this.$refs);
          this.$refs.search_input.focus();
        })
      }
    },
    filteredItems(arr, filter) {
      console.log(filter);

      if (filter == '')
        return arr;

      return arr.filter((item) => {
        if (item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
          return true;
        }
        return false;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
