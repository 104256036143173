<template>
<v-card class="absolute">
    <v-app-bar density="compact"  color="green-lighten-4">
      <v-app-bar-title v-if="!mode_search" @click="toggleSearch">Избранное</v-app-bar-title>

      <v-text-field density="compact" hide-details v-if="mode_search" ref="search_input" v-model="search_filter"></v-text-field>
      
      <v-btn icon @click="toggleSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon  @click="$.emit('favorite-add')">
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
  </v-app-bar>

     <v-navigation-drawer
        v-model="drawer"
         fixed temporary
      >
        <v-list density="compact" nav >
          <v-list-subheader>Рецепты</v-list-subheader>
          <v-list-item :title="recp.name" :value="recp" v-for="recp in recipes" :key="recp.id" @click.stop="pushToRecp(recp)">
            <template>
              <v-list-item-title>{{ recp.name }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
        v-model="list_drawer" fixed temporary
          
      >
        <v-list density="compact" nav >
          <v-list-subheader>Покупки</v-list-subheader>
          <v-list-item :title="recp.name" :value="recp" v-for="recp in buylist" :key="recp.id" @click.stop="pushToList(recp)">
            <template>
              <v-list-item-title>{{ recp.name }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
<v-main>
        <v-container class="mb-6">
            <v-row align="start">
                <v-col v-for="product in filteredItems(favorites, search_filter)" :key="product.id" cols="6" class="pa-1">
                <v-card class="" style="aspect-ratio: 9/10" @click="$.emit('product-select', product)">
                   
                  
                    
                    <v-card-text>
                        {{ product.name }}
                    </v-card-text>

                    <v-card-title>
                        {{ product.price }}₽
                    </v-card-title>

                    <v-card-subtitle>
                        {{ getPrice100(product) }}
                    </v-card-subtitle>

                    <v-card-actions>
                      <v-btn size="small" density="comfortable" color="surface-variant" variant="text" icon="mdi-heart"  @click.stop="toggleFav(product)"></v-btn>
                      <v-btn size="small" density="comfortable" color="surface-variant" variant="text" icon="mdi-bookmark-multiple-outline"  @click.stop="togglePushDrawer(product)"></v-btn>
                      <v-btn size="small" density="comfortable" color="surface-variant" variant="text" icon="mdi-format-list-checkbox" @click.stop="toggleListDrawer(product)"></v-btn>
                      <v-btn size="small" density="comfortable" color="surface-variant" variant="text" icon="mdi-information"></v-btn>
                    </v-card-actions>
                    
                </v-card>
                </v-col>
            </v-row>
        </v-container>

      </v-main>
  </v-card>
</template>

<script>

export default {
  name: 'PageFavorites',
  emits: ['recipe-create', 'recipe-delete', 'recipe-show'],
  props: {
    msg: String,
    favorites: Array,
    recipes: Array,
    buylist: Array
  },
  data() {
    return {
        mode_search: false,
        search_filter: '',
        drawer: false,
        list_drawer: false,
        push_product: null
    }
  },
  methods: {
    getPrice100(product) {
      if (product.unit == 'шт' && product.weight)
        return parseFloat(product.price / product.weight * 100).toFixed(2) + '₽ за 100 гр';
      if (product.unit == 'кг')
        return parseFloat(product.price / 10).toString() + '₽ за 100 гр';
      if (product.unit == '100г')
        return product.price + '₽ за 100 гр';

        return `unit = '${product.unit}'`;
    },
    getFav(product) {
      if (this.favorites.includes(product.id)) {
        return "mdi-heart";
      } else {
        return "mdi-heart-outline";
      }

    },
    pushToRecp(recp) {
      this.$.emit('product-push-recipe', recp, this.push_product);
    },
    pushToList(list) {
      this.$.emit('product-push-buylist', list, this.push_product);
    },
    togglePushDrawer(product) {
      this.push_product = product;
      this.drawer = !this.drawer;
    },
    toggleListDrawer(product) {
      this.push_product = product;
      this.list_drawer = !this.list_drawer;
    },
    getBgImage() {
        //console.log(product);

        return 'https://cdn-img.perekrestok.ru/i/800x800-fit/xdelivery/files/d6/37/edf37a0dd44307ab02c48eb29737.jpg';

    },
    toggleSearch() {
        this.mode_search = !this.mode_search

        if (this.mode_search) {
            console.log(this.$refs);
           //this.$refs.search.focus();

           this.$nextTick(() => {
            console.log(this.$refs);
            this.$refs.search_input.focus();
            })
        }
    },
    toggleFav(product) {
      this.$.emit('product-favorite', product, true)
    },
    filteredItems(arr, filter) {
      console.log(filter);

      if (filter == '')
        return arr;

      return arr.filter((item) => {
        if (item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
          return true;
        }
        return false;
      });
    },
    doShare(e, rec) {

       e.stopPropagation();

       console.log(rec)
       this.$.emit('recipe-share'); 
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search_input {
    margin-left: 5px;
    margin-right: 5px;
}

.v-card-text {
  height: 76px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
</style>
