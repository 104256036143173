import Net from "./common.js"

export default {

    getFamilyMembers() {

        return Net.fetch("family_members", {method: 'GET'}).then((family) => {
            console.log(family);
            if (family.count == 0)
                this.familyMemeberAdd()

            this.familyMembers = [];

            family.results.forEach((user) => {
                this.familyMembers.push({
                    id: user.id,
                    user: user.user,
                    name: user.name,
                    sex: user.sex == 1 ? 1 : 2,
                    weight: user.weight ? user.weight : 80,
                    age: user.age ? user.age : 30,
                    height: user.height ? user.height : 180,
                    lifestyle: user.lifestyle ? user.lifestyle : 1,
                    target: user.target ? user.target : 1,                    
                    protein: user.protein ? user.protein : 30,
                    fat: user.fat ? user.fat : 30,
                    carbohydrates: user.carbohydrates ? user.carbohydrates : 40                 
                })
            });

            
        })
  
        
    },

    familyMemeberAdd() {
        return Net.fetch("family_members", {
            name: "Пользователь"
        }).then(() => {
            this.getFamilyMembers()
        })
    },

    familyMembersStore(users) {
        console.log(users);
        this.selectedPage = 'main'

        var list = users.map((user) => {
            this.storeFamilyMember(user)
        })

        return Promise.all(list).then(this.getFamilyMembers);
    },

    storeFamilyMember(user) {

        return Net.fetch("family_members/"+user.id, {
            method: "PUT",
            ...user
        })
    }
}