<template>
    <div >
        <v-app-bar density="compact"  color="green-lighten-4">
        <v-btn icon @click="doExit(0)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
          <v-app-bar-title v-if="!mode_search" @click="toggleSearch">Запасы</v-app-bar-title>
    
          <v-text-field density="compact" hide-details v-if="mode_search" ref="search_input" v-model="search_filter"></v-text-field>
          
          <v-btn icon @click="toggleSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn icon  @click="$.emit('recipe-create')">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
      </v-app-bar>
    
      <v-main>
    
        
    
        <v-container class="d-flex flex-column pa-0">     
            <v-list v-for="rec in filteredItems(recipes, search_filter)" :key="rec.id" density="compact">
     
              <v-list-item 
            
                prepend-icon="mdi-bookmark-multiple-outline" 
                :title="rec.name"
                @click="$.emit('recipe-show', rec)">
    
                <template v-slot:append>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn  icon="mdi-chevron-down" v-bind="props" variant="text" />
                    </template>
                    
                    <v-list>
                        <v-list-item prepend-icon="mdi-share-variant">
                            <v-list-item-title @click="(e) => { doShare(e, rec) } ">Поделиться</v-list-item-title>
                        </v-list-item>
                        <v-list-item prepend-icon="mdi-trash-can-outline">
                            <v-list-item-title  @click="$.emit('recipe-delete', rec)">Удалить</v-list-item-title>
                        </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-list-item>
            </v-list>
    
    
            <!-- <v-card v-for="rec in filteredItems(recipes, search_filter)" :key="rec.id" class="ma-1 w-100" @click="$.emit('recipe-show', rec)">
                <v-img height="100px" cover src="@/assets/images/recipe.jpg">
                    
                </v-img>
                <v-card-actions>
                    <v-card-title>{{ rec.name }}</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn  icon="mdi-share-variant" @click="(e) => { doShare(e, rec) } "></v-btn>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn  icon="mdi-trash-can-outline" v-bind="props" />
                        </template>
                        
                        <v-list>
                            <v-list-item>
                                <v-list-item-title @click="$.emit('recipe-delete', rec)">Удалить</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-actions>
            </v-card> -->
    
        </v-container>
      </v-main>
    </div>
    </template>
    
    <script>
    
    export default {
      name: 'PageRecipes',
      emits: ['recipe-create', 'recipe-delete', 'recipe-show'],
      props: {
        msg: String,
        recipes: Array
      },
      data() {
        return {
            mode_search: false,
            search_filter: '',
            share_menu: false,
            internalRecipe: {
                changed: false
            }
        }
      },
      methods: {
        toggleSearch() {
            this.mode_search = !this.mode_search
    
            if (this.mode_search) {
                console.log(this.$refs);
               //this.$refs.search.focus();
    
               this.$nextTick(() => {
                console.log(this.$refs);
                this.$refs.search_input.focus();
                })
            }
        },
        filteredItems(arr, filter) {
          //console.log(filter);
    
          if (filter == '')
            return arr;
    
          return arr.filter((item) => {
            if (item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
              return true;
            }
            return false;
          });
        },
        doShare(e, rec) {
    
           e.stopPropagation();
    
           console.log(rec)
           this.$.emit('recipe-share'); 
        },

        doExit(num) {
        if (num == 0) {
            if (this.internalRecipe.changed)
                this.dialog = true;
            else
                this.$.emit('exit');
        }

        if (num == -1)
            this.$.emit('exit');

        if (num == 1) {
            this.$.emit('store', this.internalRecipe, true);
        }

        if (num == 2) {
            this.internalRecipe.changed = false;
            this.$.emit('store', this.internalRecipe, true);
            
        }
    }, 
    
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    .search_input {
        margin-left: 5px;
        margin-right: 5px;
    }
    .v-card {
         background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);
    }
    </style>
    