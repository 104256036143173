<template>
    <div class="absolute">
      <v-app-bar density="compact"   color="green-lighten-4">
            <v-btn icon @click="doExit(0)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
    
            <v-app-bar-title v-if="!header_search">Продукты исполнение</v-app-bar-title>
            <v-text-field density="compact" hide-details="true" v-if="header_search" v-model="internalRecipe.name" @change="setChangedState()"></v-text-field>
    
            <v-btn variant="text" icon="mdi-share-variant"></v-btn>
      </v-app-bar>
    
      <v-main>
    
    
        
    
       
            <v-list v-model:opened="opened" class="pa-0">
                <v-list-group value="0">
                    <template v-slot:activator="{ props }">
                        <v-list-item  class="bg-green-lighten-4" v-bind="props" title="Для пользователей">
                        </v-list-item>
                    </template>
    
                    <v-list-item>
                        <v-list-item-title>
                    
                           
    
                                <v-card-title>
                                    <v-select :items="users" item-title="name" item-value="id" v-model="currentUserName" @update:modelValue="currentUserName"> 
                                    </v-select>
                                </v-card-title>
    
                                
                           
                        </v-list-item-title>
                    </v-list-item>
    
                    
                 
                    
                   
    
                </v-list-group>
    
                <v-list-group value="1">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="bg-green-lighten-4" >
                            <v-list-item-title class="d-flex">
                                
                                Выбор периода
                                <v-spacer></v-spacer>
                                <v-icon v-if="opened.includes('1')" class="align-right" @click.stop="$.emit('recipe-product-add', internalRecipe)">mdi-plus-circle</v-icon>
                                
                            </v-list-item-title>
                        </v-list-item>
       
                    </template>
    
                  
                    <v-list-item v-for="(prod) in internalRecipe.items" :key="prod"
                    
                        :title="prod._it.name"
                        
                        :class="weightIsSet(prod)">
    
                        <template v-slot:append>
                            <div class="d-flex flex-column actions">
                                <v-icon @click="swapProduct(prod)" class="pa-5">mdi-swap-horizontal</v-icon>                            
                                <v-icon @click="removeDialog(0, prod)" class="pa-5">mdi-trash-can-outline</v-icon>
                            </div>
                        </template>
    
                        <template v-slot:subtitle>
                            <v-table  density="compact">
                                <thead>
                                    <tr>
                                        <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                        Б
                                        </th>
                                        <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                        Ж
                                        </th>
                                        <th v-if="variant == 'normal'" class="text-left text-subtitle-2">
                                        У
                                        </th>
                                        
                                        <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                        Ккал
                                        </th>
                                        <th v-if="variant == 'normal'" class="text-left text-subtitle-2" >
                                        Вес
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @click="weightDialog(0, prod)" >
                                        <td class="text-left text-subtitle-2">
                                            {{ (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") }}
                                        </td>
                                        <td class="text-left text-subtitle-2">
                                            {{ (prod._it.fats ? this.getVal(prod._it.fats) : "0") }} 
                                        </td>
                                        <td class="text-left text-subtitle-2">
                                            {{ (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") }}
                                        </td>
                                        <td class="text-left text-subtitle-2">
                                            {{ (prod._it.energy ? this.getVal(prod._it.energy) : "0") }}
                                        </td>
                                        <td class="text-left text-subtitle-2">
                                            {{ prod.quantity ? this.getVal(prod.quantity, 0) : "0" }}
                                            <v-icon >mdi-pencil-outline</v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                                
                        </template>
                    </v-list-item>
                
                </v-list-group>
    
    
    
                <v-list-group value="2">
    
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Список продуктов" class="bg-green-lighten-4" ></v-list-item>
                    </template>
    
                    <v-list-item>
    
                        <v-textarea
                            variant="underlined"
                           
                            auto-grow
                            @keyup="internalRecipe.changed = true"
                            v-model="internalRecipe.description"
                            >
                        </v-textarea>
                    </v-list-item>
                </v-list-group>

                <v-list-group value="3">
    
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Сортировка" class="bg-green-lighten-4" ></v-list-item>
                    </template>

                    <v-list-item>

                        <v-textarea
                            variant="underlined"
                        
                            auto-grow
                            @keyup="internalRecipe.changed = true"
                            v-model="internalRecipe.description"
                            >
                        </v-textarea>
                    </v-list-item>
                </v-list-group>

                <v-list-group value="4">
    
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Название продукта" class="bg-green-lighten-4" ></v-list-item>
                    </template>

                    <v-list-item>

                        <v-textarea
                            variant="underlined"
                        
                            auto-grow
                            @keyup="internalRecipe.changed = true"
                            v-model="internalRecipe.description"
                            >
                        </v-textarea>
                    </v-list-item>
                </v-list-group>
            </v-list>
    
    
    
    
    
         
        <v-dialog v-model="dialog" persistent width="auto">
            <v-card>
                <v-card-text>Сохранить рецепт перед выходом?</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="red-darken-1"  variant="text" @click="doExit(-1)">
                    Не сохранять
                </v-btn>
                <v-btn color="green-darken-1" variant="text" @click="doExit(1)">
                    Сохранить
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
        <v-dialog v-model="dialog_weight" persistent width="auto">
            <v-card>
                <v-card-text>Укажите вес продукта</v-card-text>
                <v-card-text>
                    <v-text-field density="compact" hide-details="true" v-model="prod_weight">
    
                        <template v-slot:prepend>
                            <v-icon icon="mdi-minus-circle" @click="increaseWeight(-50)" />
                        </template>    
    
                        <template v-slot:append>
                            <v-icon icon="mdi-plus-circle" @click="increaseWeight(50)" />
                        </template>    
    
                        
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="red-darken-1"  variant="text" @click="weightDialog(-1)">
                    Закрыть
                </v-btn>
                <v-btn color="green-darken-1" variant="text" @click="weightDialog(1)">
                    Сохранить
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
        <v-dialog v-model="dialog_remove" persistent width="auto">
            <v-card>
                <v-card-text>Удалить продукт из рецепта?</v-card-text>
                
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="green-darken-1"  variant="text" @click="removeDialog(-1)">
                    Отмена
                </v-btn>
                <v-btn color="red-darken-1" variant="text" @click="removeDialog(1)">
                    Удалить
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
      </v-main>
    
      <v-footer
        app
        fixed
        grow
        class="justify-center"
        color="transparent"
        v-if="internalRecipe.changed"
      >
    
        <v-btn   color="green-darken-3" prepend-icon="mdi-content-save"  size="large" @click="doExit(2)">Сохранить</v-btn>
    
      </v-footer>
    </div>
    </template>
    
    <script>
    
    import Common from "@/common.js";
    
    export default {
      name: 'PageProductExec',
      emits: ['recipe-exit', 'recipe-store', 'recipe-product-add', 'product-swap'],
      props: {
        msg: String,
        recipe: Object,
        users: Array
      },
      data() {
        return {
            currentUserName: '',
            recipes: [1,2,3],
            header_search: false,
            internalRecipe: {portions: 1, items: []},
            dialog: false,
            dialog_weight: false,
            dialog_remove: false,
            prod_weight: 0,
            prod: null,
            variants: ['normal', 'edit'],
            variant: 'normal',
            changed: false,
            show_desc: false,
            opened: ["0", "1"]
        }
      },
      methods: {
        setChangedState() {
            this.internalRecipe.changed = true;
        },
        getSubtitle(prod) {
            return "<table><tr><td>" + 
                (prod._it.proteins ? this.getVal(prod._it.proteins) : "0") + "</td><td>" +                       
                (prod._it.fats ? this.getVal(prod._it.fats) : "0") + "</td><td>" +            
                (prod._it.carbohydrates ? this.getVal(prod._it.carbohydrates) : "0") + "</td></tr></table>";
        },
        toggleHeader() {
            this.header_search = !this.header_search
        },
        increase(val, obj, field) {
    
            console.log(obj[field])
            this.internalRecipe.changed = true;
    
            if (obj[field]) {
    
                if (obj[field] + val > 0)
                    obj[field] = parseFloat(obj[field]) + val;
            } 
    
        },
        weightIsSet(prod) {
            if (prod._it.unit == 'nan')
                return {"bg-red-lighten-4": true}
        },
        increaseWeight(val) {
            if (val < 0 && this.prod_weight >= -val)
                this.prod_weight = parseFloat(this.prod_weight) + val;
            if (val > 0)
                this.prod_weight = parseFloat(this.prod_weight) + val ;
        },
        weightDialog(num, prod) {
            if (num == 0) {
                this.prod_weight = prod.quantity;
                this.prod = prod;
                this.dialog_weight = true;
            }
    
            if (num == -1)
                this.dialog_weight = false;
    
            if (num == 1) {
                this.prod.quantity = this.prod_weight;
                this.dialog_weight = false;
                this.internalRecipe.changed = true;
                //this.$.emit('recipe-store', this.internalRecipe);
            }
        },
        removeDialog(num, prod) {
            if (num == 0) {
                this.prod = prod;
                this.dialog_remove = true;
            }
    
            if (num == -1)
                this.dialog_remove = false;
    
            if (num == 1) {
                
                this.dialog_remove = false;
                this.internalRecipe.changed = true;
                
                this.internalRecipe.items = this.internalRecipe.items.filter((item) => {
                    return item.product != this.prod.product;
                })
            }
        },
        swapProduct(prod) {
            this.$.emit('product-swap', prod, this.internalRecipe);
        },
        doExit(num) {
            if (num == 0) {
                if (this.internalRecipe.changed)
                    this.dialog = true;
                else
                    this.$.emit('navigate', 'main');
            }
    
            if (num == -1)
                this.$.emit('recipe-exit');
    
            if (num == 1) {
                this.$.emit('recipe-store', this.internalRecipe, true);
            }
    
            if (num == 2) {
                this.internalRecipe.changed = false;
                this.$.emit('recipe-store', this.internalRecipe, true);
                
            }
        }, 
        productAddEvent(product) {
            console.log("Product add", product)
             console.log(this.internalRecipe);
            //this.internalRecipe.items.push(product);
        },
        remove(key) {
            console.log(key);
            
            this.internalRecipe.items.splice(key, 1);
            
        },
        getVal(val, fixed=0) {
    
            
            if (!isNaN(val)) {
                return parseFloat(val).toFixed(fixed);
            } else {
                return parseFloat("0").toFixed(fixed);
            }
        },
        getSum: Common.getSum,
        getPrice: Common.getPrice,
        getWeight: Common.getWeight,
        toggleMode() {
            if (this.variant == 'normal')
                this.variant = 'edit';
            else 
                this.variant = 'normal';
    
            
    
        }
      },
      mounted() {
        /*console.log(this.recipe);
    
    
        this.internalRecipe = this.recipe;*/
        this.currentUserName = this.users[0].name;

        if (!this.internalRecipe.portions)
            this.internalRecipe.portions = 1;
    
    
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    
    .v-col {
        padding-top: 0;
        padding-bottom: 0;
    }
    .v-list-subheader {
        padding-top: 0;
    }
    
    .count {
        min-width: 130px;
    }
    
    tr.hidden {
        display: none;
    }
    
  
    
    .head {
        padding: 0 8px;
    }
    
    .v-table > .v-table__wrapper > table > tbody > tr > td,
    .v-table > .v-table__wrapper > table > thead > tr > th {
        padding: 0 8px;
    }
    </style>
    