<template>

  <div class="absolute">
      <FrameTitul 
            @categories-show="categoriesShow" 
            @catalog-callback="productSelect"
            :shops="shops"
            :closeable="false"
            
        >

          <v-container>
            <v-btn block color="green-darken-3" @click="$.emit('configure-users')">Настройка пользователей</v-btn>
          </v-container>

          <v-container>
            <v-btn block color="green-darken-3" @click="$.emit('plan-users')">План питания</v-btn>
          </v-container>

          <v-container>
            <v-btn block color="green-darken-3" @click="$.emit('navigate', 'stocks')">Запасы</v-btn>
          </v-container>

          <v-container>
            <v-btn block color="green-darken-3" @click="$.emit('navigate', 'productexec')">Продукты исполнение</v-btn>
          </v-container>

          <v-container>
            <v-btn block color="green-darken-3" @click="$.emit('navigate', 'productgen')">Продукты формирование</v-btn>
          </v-container>

         
      
      </FrameTitul>        



      <v-dialog v-model="productDialog.show" persistent width="auto" height="80%">
        <v-card :title="productDialog.product.name" height="100%" class="product_list">
            <v-card-text>
              <v-list>
                <v-list-item title="Производитель:" :subtitle="productDialog.product.manufacturer">                    
                </v-list-item>
                <v-list-item title="Торговая марка:" :subtitle="productDialog.product.brand">                    
                </v-list-item>
                <v-list-item title="Состав:" :subtitle="productDialog.product.composition" class="list_composition">                    
                </v-list-item>
                <v-list-item title="Цена:" :subtitle="productDialog.product.price">                    
                </v-list-item>
              </v-list>

              <v-table density="compact" class="head" >
                    <thead>
                    <tr>
                        <th class="text-left text-subtitle-2">
                        Б
                        </th>   
                        <th class="text-left text-subtitle-2">
                        Ж
                        </th> 
                        <th class="text-left text-subtitle-2">
                        У
                        </th>                  
                        <th class="text-left text-subtitle-2">
                        Ккал
                        </th>
                        <th class="text-left text-subtitle-2">
                        Вес
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="text-orange-darken-3">
                        <td class="text-left text-subtitle-2">
                        {{ getVal(productDialog.product.proteins) }}
                        </td>
                        <td class="text-left text-subtitle-2">
                        {{ getVal(productDialog.product.fats) }}
                        </td>
                        <td class="text-left text-subtitle-2">
                        {{ getVal(productDialog.product.carbohydrates) }}
                        </td>
                        <td class="text-left text-subtitle-2">
                        {{ getVal(productDialog.product.energy) }}
                        </td>
                        <td class="text-left text-subtitle-2">
                        {{ getVal(productDialog.product.weight)  }}
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="green-darken-1" variant="text" @click="productDialog.reveal = true">
                Подробнее
            </v-btn>
            <v-spacer></v-spacer>                        
            <v-btn color="green-darken-1" variant="text" @click="productDialog.show = false">
                Закрыть
            </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <v-card v-if="productDialog.reveal" class="v-card--reveal" height="100%">
                <v-card-text  title="Описание">
                  
                  <div class="text--primary">
                    {{ productDialog.product.description }} 
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn variant="text" color="teal-accent-4" @click="productDialog.reveal = false">
                    Назад
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
        </v-card>
    </v-dialog>
        

  </div>
</template>

<script>


import Net from "@/common.js"
import FrameTitul from '@/components/FrameTitul.vue'

export default {
  name: 'PageMain',
  components: {
    FrameTitul
  },
  emits: ['categories-show', 'logout', 'product-select', 'configure-users'],
  props: {
    msg: String,
    shops: Array,
    return_product: Boolean
  },
  data() {
    return {

      selectedShop: null,
      selectedSubCat: null,
      products: [],
      favorites: [],  
      productDialog: {
        show: false,
        product: null,
        reveal: false
      }
    }
  },
  methods: {
    getVal(val, fixed=0) {

        
    if (!isNaN(parseFloat(val))) {
        return parseFloat(val).toFixed(fixed);
    } else {
        return parseFloat("0").toFixed(fixed);
    }
    },

    productSelect(product) {
      this.productDialog.product = product;
      this.productDialog.show = true;
    },


    categoriesShow(shop) {

      this.selectedShop = shop;

      Net.fetch('categories', {method: 'GET', shop: this.selectedShop.id }).then((jsonData) => {
        console.log(jsonData);
        this.categories = jsonData;
        this.categories.forEach((cat) => {
          cat.child = cat.child.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
        this.submenu = 'categories';
      })
    },
    catalogSelect(cat) {

      this.mode_search = false;

      Net.fetch('categories/'+cat.id, {method: 'GET', shop: this.selectedShop.id}).then((jsonData) => {
        console.log(jsonData);

        this.subCategories = jsonData;
        this.subCategories.child = this.subCategories.child.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.submenu = 'catalog';
        
        this.subCategorySelect(this.subCategories.child[0]);
      })

      console.log(cat);
    },
    subCategorySelect(subCat) {

      console.log(subCat);
      this.selectedSubCat = subCat;

      Net.fetch('products', {method: 'GET', shop: this.selectedShop.id, category: subCat.id}).then((jsonData) => {
        console.log(jsonData);
        this.products = jsonData.results;
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-card {
  /*background: linear-gradient(205deg, #AdA 0%, #FFF 50%, #CeC 100%);*/
}


.search_input {
  border: none;
  display: inline-flex;
  flex-grow: 4;
}
</style>
